import * as actionTypes from "../actions/actionTypes";
import _ from 'lodash'

const initialState = {
  totalNews: 0,
  totalDealer: 0,
  totalMember: 0,
  totalPoint: 0,

  dataPlatformAuth: [],
  dataPlatformNotAuth: [],
  top5Interaction: [],
  onlineTime: [],
  top5GamerOnline: [],
  totalViewNews: 0,
  totalRequestBH: [],
  totalRequestPM: [],
  ktd: [],
  top3News: [],
  news: [],
  point: {},
  playQuantity: {},
  top3ProductScan: [],
  playTime: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_TOTAL_NEWS_SUCCESS:
      return {
        ...state,
        totalNews: action.payload,
      };
    case actionTypes.GET_TOTAL_DEALER_SUCCESS:
      return {
        ...state,
        totalDealer: action.payload,
      };
    case actionTypes.GET_TOTAL_MEMBER_SUCCESS:
      const { data, gender_data } = action.payload;
      return {
        ...state,
        totalMember: _.sumBy(data, d => d.total),
        dataMemberType: data,
        dataPlatformAuth: data.find((i) => i.type === 1).data,
        dataPlatformNotAuth: data.find((i) => i.type === -1).data,
        dataGender: gender_data,
      };
    case actionTypes.TOP5_INTERACTION_SUCCESS:
      return {
        ...state,
        top5Interaction: action.payload.data,
      };
    case actionTypes.TOP5_GAMER_ONLINE_SUCCESS:
      return {
        ...state,
        top5GamerOnline: action.payload,
      };
    case actionTypes.ONLINE_TIME_SUCCESS:
      return {
        ...state,
        onlineTime: action.payload.data,
      };
    case actionTypes.TOTAL_VIEW_NEWS_SUCCESS:
      return {
        ...state,
        totalViewNews: action.payload.data,
      };
    case actionTypes.REQUEST_BH_SUCCESS:
      return {
        ...state,
        totalRequestBH: action.payload,
      };
    case actionTypes.REQUEST_PH_SUCCESS:
      return {
        ...state,
        totalRequestPM: action.payload,
      };
    case actionTypes.KTD_SUCCESS:
      return {
        ...state,
        ktd: action.payload,
      };
    case actionTypes.TOP3_NEWS_SUCCESS:
      return {
        ...state,
        top3News: action.payload.data,
      };
    case actionTypes.GET_NEWS_SUCCESS:
      return {
        ...state,
        news: action.payload.data,
      };
    case actionTypes.GET_TOTAL_POINT_SUCCESS:
      return {
        ...state,
        totalPoint: action.payload,
      };
    case actionTypes.GET_POINT_SUCCESS:
      return {
        ...state,
        point: action.payload,
      };
    case actionTypes.GET_PLAY_QUANTITY_SUCCESS:
      return {
        ...state,
        playQuantity: action.payload,
      };
    case actionTypes.TOP3_PRODUCT_SCAN_SUCCESS:
      return {
        ...state,
        top3ProductScan: action.payload,
      };
    case actionTypes.GET_PLAY_TIME_SUCCESS:
      return {
        ...state,
        playTime: action.payload,
      };
    case actionTypes.GET_DASHBOARD_STATISTIC_SUCCESS:
      return {
        ...state,
        dashboardStatistic: action.payload
      };
    case actionTypes.FETCH_DASHBOARD_SCANNING_SUCCESS:
      return {
        ...state,
        dashboardScanning: action.payload
      };
    case actionTypes.FETCH_EVENT_TOTAL_ENTRY_SUCCESS:
      return {
        ...state,
        eventTotalEntry: action.payload
      };
    case actionTypes.FETCH_APP_MODULE_USAGE_TIME_SUCCESS:
      return {
        ...state,
        appModuleUsageTime: action.payload
      };
    case actionTypes.FETCH_TOP5_SEARCH_PRODUCT_SUCCESS:
      return {
        ...state,
        top5SearchProduct: action.payload
      };
    default:
      return state;
  }
}
