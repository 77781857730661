import * as actionTypes from "../actions/actionTypes";
const initialState = {
  loading: false,
  total: 0,
  data: [],
};
const ColorRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FET_REQUEST_COLOR:
      state.loading = true;
      return { ...state };
    case actionTypes.FET_REQUEST_COLOR_SUCCESS:
      state.loading = false;
      state.total = action.payload?.data?.total;
      state.data = action.payload?.data?.data;
      return { ...state };
    case actionTypes.FET_REQUEST_COLOR_FAIL:
      state.loading = false;
      return { ...state };
    case actionTypes.CLEAR_REQUEST_COLOR:
      return { ...initialState }
    default:
      return { ...state };
  }
};
export default ColorRequestsReducer;
