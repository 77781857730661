import produce from "immer"
import moment from 'moment-mini';

const MemberReducer = produce((draft = {}, action={}) => {
  switch (action.type) {
    // case "FETCH_MEMBER":
    //   draft.loading = true
    //   delete draft.error
    //   return draft
    // case "PUT_MEMBER_SUCCESS":
    // case "FETCH_MEMBER_SUCCESS":
    //   draft = action.payload.data
    //   if(draft.birthday){
    //     draft.birthday = moment(draft.birthday)
    //   }
    //   draft.gender = draft.gender+1
    //   if(draft.avatar){
    //     draft.avatar = process.env.REACT_APP_FILE_API + "file/" + draft.avatar + "?size=480"
    //   }
    //   if(draft.main_dealer){
    //     draft.main_dealer.key = draft.main_dealer.id.toString()
    //     draft.main_dealer.label = draft.main_dealer && draft.main_dealer.short_name ? draft.main_dealer.short_name.toString() : ""
    //     draft.main_dealer.title = draft.main_dealer && draft.main_dealer.name ? draft.main_dealer.name.toString() : ""
    //   }
    //   if(draft.sub_dealer){
    //     draft.sub_dealer.key = draft.sub_dealer.id.toString()
    //     draft.sub_dealer.label = draft.sub_dealer && draft.sub_dealer.short_name ? draft.sub_dealer.short_name.toString() : ""
    //     draft.sub_dealer.title = draft.sub_dealer && draft.sub_dealer.name ? draft.sub_dealer.name.toString() : ""
    //   }
    //   if(draft.bank_info && draft.bank_info.city && draft.bank_info.city.key){
    //     draft.bank_info.city.key = draft.bank_info.city.key.toString()
    //   }
    //   if(draft.extend_data && draft.extend_data.care_source && !draft.extend_data.care_source.id){
    //     draft.extend_data.care_source = findCareSource(draft.extend_data.care_source)
    //   }
    //   if(draft.extend_data && draft.extend_data.recruitment_source && !draft.extend_data.recruitment_source.id){
    //     draft.extend_data.recruitment_source = findSource(draft.extend_data.recruitment_source)
    //   }
    //   if(draft.cs && draft.cs.status && !draft.cs.status.id){
    //     draft.cs.status = findRelate(draft.cs.status)
    //   }
    //   draft.loading = false
    //   return draft
    // case "PUT_MEMBER_BANK_SUCCESS":
    //   // draft
    //   draft.bank_owner = action.payload.member_info.bank_owner
    //   draft.bank_number = action.payload.member_info.bank_number
    //   draft.bank_info = {
    //     bank: action.payload.bank,
    //     city: action.payload.city,
    //     branch: action.payload.branch,
    //   }
    //   return draft
    // case "FETCH_MEMBER_FAILED":
    //   draft.loading = false
    //   draft.error = true
    //   return draft
    // case "CLEAR_MEMBER":
    //   draft = {}
    //   return draft
    default:
      return draft
  }
})

export default MemberReducer;