import * as StatusColor from "./StatusColor";
const DRAFT = {
  id: 10,
  key: "10",
  label: "Lưu tạm",
  color: StatusColor.light_blue.color,
};
const REQUEST = {
  id: 1,
  key: "1",
  label: "Chờ duyệt",
  color: StatusColor.purple.color,
};
const APPROVED = {
  id: 2,
  key: "2",
  label: "Đã duyệt",
  color: StatusColor.green.color,
};
const DENIED = {
  id: -2,
  key: "-2",
  label: "Đã từ chối",
  color: StatusColor.grey.color,
};
const SENDING = {
  id: 3,
  key: "3",
  label: "Đang gửi",
  color: StatusColor.yellow.color,
};
const STOPPED = {
  id: -3,
  key: "-3",
  label: "Tạm dừng",
  color: StatusColor.orange.color,
};
const COMPLETE = {
  id: 4,
  key: "4",
  label: "Hoàn tất",
  color: StatusColor.dark_green.color,
};
const DELETED = {
  id: -1,
  key: "-1",
  label: "Đã hủy",
  color: StatusColor.black.color,
};

const NORMAL_CATE = {
  id: 1,
  key: "1",
  label: "Thông tin chung",
  options: [{ id: 1, key: "1", label: "Xem nội dung thông báo" }],
};

const EVENT_CATE = {
  id: 2,
  key: "2",
  label: "Sự kiện",
  options: [
    { id: 1, key: "1", label: "Xem nội dung thông báo" },
    { id: 2, key: "2", label: "Xem sự kiện liên quan" },
  ],
};
const NEWS_CATE = {
  id: 3,
  key: "3",
  label: "Bài viết",
  options: [
    { id: 1, key: "1", label: "Xem nội dung thông báo" },
    { id: 2, key: "2", label: "Xem bài viết liên quan" },
  ],
};
const LUCKY_DRAW_CATE = {
  id: 4,
  key: "4",
  label: "Vòng quay may mắn",
  options: [{ id: 1, key: "1", label: "Xem vòng xoay may mắn" }],
};
const SYSTEM_ALL_CATE = {
  id: 5,
  key: "5",
  label: "Thông báo chung",
  options: [{ id: 1, key: "1", label: "Xem nội dung thông báo" }],
};
const SYSTEM_PERSONAL_CATE = {
  id: 6,
  key: "6",
  label: "Thông báo cá nhân",
  options: [{ id: 1, key: "1", label: "Xem nội dung thông báo" }],
};
const UPDATE_CATE = {
  id: 7,
  key: "7",
  label: "Cập nhật app",
  options: [{ id: 1, key: "1", label: "Xem nội dung thông báo" }],
};

const ALL = {
  id: 1,
  key: "1",
  label: "Tất cả",
  color: StatusColor.green.color,
};
const PRIVATE = {
  id: 2,
  key: "2",
  label: "Riêng tư",
  color: StatusColor.blue.color,
};
const TARGET_GROUP = {
  id: 3,
  key: "3",
  label: "Nhóm",
  color: StatusColor.grey.color,
};

const SCHEDULE_NOW = {
  id: 1,
  key: "1",
  label: "Gửi ngay",
  color: StatusColor.green.color,
};
const SCHEDULE_LATER = {
  id: 2,
  key: "2",
  label: "Gửi vào lúc",
  color: StatusColor.orange.color,
};

const EMBED_IMG = {
  id: 1,
  key: "1",
  label: "Hình ảnh",
};
const EMBED_YOUTUBE = {
  id: 2,
  key: "2",
  label: "Youtube",
};
const EMBED_MICROSITE = {
  id: 3,
  key: "3",
  label: "Link",
  color: StatusColor.grey.color,
};

const list_status = [
  DRAFT,
  REQUEST,
  APPROVED,
  DENIED,
  SENDING,
  STOPPED,
  COMPLETE,
  DELETED,
];
const findStatus = (status, labelText) => {
  let data = list_status.find((s) => s.id === status * 1);
  if (labelText) return data[labelText] || "";
  return data;
};

const list_cate = [
  NORMAL_CATE,
  EVENT_CATE,
  NEWS_CATE,
  LUCKY_DRAW_CATE,
  UPDATE_CATE,
];
const findCate = (cate, labelText) => {
  let data = list_cate.find((s) => s.id === cate * 1);
  if (labelText) return data?.[labelText] || "";
  return data;
};

const list_target = [ALL, PRIVATE];
const findTarget = (type, labelText) => {
  let data = list_target.find((s) => s.id === type * 1);
  if (labelText) return data[labelText] || "";
  return data;
};

const list_schedule = [SCHEDULE_NOW, SCHEDULE_LATER];
const findSchedule = (type, labelText) => {
  let data = list_schedule.find((s) => s.id === type * 1);
  if (labelText) return data[labelText] || "";
  return data;
};

const list_embed = [EMBED_IMG, EMBED_YOUTUBE, EMBED_MICROSITE];
// const list_embed = [EMBED_IMG]
const findEmbed = (type, labelText) => {
  let data = list_embed.find((s) => s.id === type * 1);
  if (labelText) return data[labelText] || "";
  return data;
};

export {
  DRAFT,
  REQUEST,
  APPROVED,
  DENIED,
  SENDING,
  STOPPED,
  COMPLETE,
  DELETED,
  list_status,
  findStatus,
  EVENT_CATE,
  NEWS_CATE,
  LUCKY_DRAW_CATE,
  NORMAL_CATE,
  UPDATE_CATE,
  list_cate,
  findCate,
  ALL,
  PRIVATE,
  TARGET_GROUP,
  list_target,
  findTarget,
  SCHEDULE_NOW,
  SCHEDULE_LATER,
  list_schedule,
  findSchedule,
  EMBED_IMG,
  EMBED_YOUTUBE,
  EMBED_MICROSITE,
  list_embed,
  findEmbed,
};
