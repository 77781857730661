import { produce } from 'immer';
import moment from 'moment-mini';

const NotificationReducer = produce((draft = {}, action = {}) => {
  switch (action.type) {
    case "FETCH_NOTIFICATIONS_SUCCESS":
      draft = action.payload
      return draft
    case "FETCH_DETAIL_NOTIFICATION_SUCCESS":
      draft = action.payload.data
      if(draft.from) {
        draft.from = moment(draft.from)
      }
      if(draft.to) {
        draft.to = moment(draft.to)
      }
      if(draft.notification_date) {
        draft.notification_date = moment(draft.notification_date)
      }
      draft.loading = false
      return draft
    case "FETCH_DETAIL_NOTIFICATION_FAILED":
      draft.loading = false
      draft.error = true
      return draft
    case "CLEAR_DETAIL_NOTIFICATION":
      draft = {}
      return draft
    default:
      return draft
  }
})

export default NotificationReducer