import * as statusColor from "./StatusColor";

// const INACTIVE = {
//   id: -1,
//   key: "-1",
//   label: "Tạm ngưng",
//   color: statusColor.grey.color
// }
// const ACTIVE = {
//   id: 1,
//   key: "1",
//   label: "Đang hoạt động",
//   color: statusColor.green.color
// }

let NORMAL = {
  id: -1,
  key: "-1",
  label: "Đang áp dụng",
  color: statusColor.blue.color,
};
let BLOCK = {
  id: 1,
  key: "1",
  label: "Không áp dụng",
  color: statusColor.grey.color,
};

let NEW = {
  id: 1,
  key: 1,
  label: "Chờ kích hoạt",
  color: statusColor.blue.color,
};
let ACTIVE = {
  id: 2,
  key: 2,
  label: "Đang hoạt động",
  color: statusColor.green.color,
};
let PENDING = {
  id: 3,
  key: 3,
  label: "Tạm ngưng",
  color: statusColor.grey.color,
};
let INACTIVE = {
  id: 4,
  key: 4,
  label: "Đã khóa",
  color: statusColor.red.color,
};

let list_status = [
  NEW,
  ACTIVE,
  // PENDING,
  INACTIVE,
];

const findStatus = (status) => list_status.find((s) => s.id === status * 1);

let list_type = [NORMAL, BLOCK];

const findType = (type, labelText) => {
  let data = list_type.find((t) => t.id === type * 1);
  if (labelText) {
    return data[labelText] || "";
  }
  return data;
};

const DEALER_TYPE_UNVERIFIED = { id: -1, name: "Chưa xác định" };
const DEALER_TYPE_MASTER_DEALER = { id: 1, name: "Master dealer" };
const DEALER_TYPE_KEY_DEALER = { id: 2, name: "Key dealer" };
const DEALER_TYPE_SUB_DEALER = { id: 3, name: "Đại lý C1" };
const DEALER_TYPE_C2 = { id: 4, name: "Đại lý C2" };
const DEALER_TYPE_MD_KD_CN = { id: 5, name: "Đại lý MD/KD-CN" };
const DEALER_TYPE_SAF = { id: 6, name: "Đại lý SAF" };
const DEALER_TYPE_PROJECT = { id: 7, name: "Đại lý Project" };

const listDealerType = [
  DEALER_TYPE_UNVERIFIED,
  DEALER_TYPE_MASTER_DEALER,
  DEALER_TYPE_KEY_DEALER,
  DEALER_TYPE_SUB_DEALER,
  DEALER_TYPE_C2,
  DEALER_TYPE_MD_KD_CN,
  DEALER_TYPE_SAF,
  DEALER_TYPE_PROJECT,
];

export {
  NEW,
  ACTIVE,
  INACTIVE,
  list_status,
  findStatus,
  NORMAL,
  BLOCK,
  list_type,
  findType,
  DEALER_TYPE_UNVERIFIED,
  DEALER_TYPE_MASTER_DEALER,
  DEALER_TYPE_KEY_DEALER,
  DEALER_TYPE_SUB_DEALER,
  DEALER_TYPE_C2,
  DEALER_TYPE_MD_KD_CN,
  DEALER_TYPE_SAF,
  DEALER_TYPE_PROJECT,
  listDealerType,
};
