import _ from "lodash";
import moment from "moment";

export const renderURL = (url, params) => {
  let paramsValue = _.compact(
    _.map(params, (p, key) => {
      if (p) {
        if (typeof p === "object") {
          if (p.id) return `${key}=${p.id}`;
          else {
            let formatDate = moment(p).format("DD-MM-YYYY");
            return `${key}=${formatDate}`;
          }
        } else {
          return `${key}=${p}`;
        }
      }
    }),
  );
  return _.map(paramsValue, (pv, index) => (!index ? pv : "&" + pv)).join("");
};

export const routerChange = (history, path, filter, replace) => {
  if (replace) {
    history.replace({
      pathname: path,
      search: "?" + renderURL(path, filter),
    });
  } else {
    history.push({
      pathname: path,
      search: "?" + renderURL(path, filter),
    });
  }
};
