export const FET_REQUEST_COLOR = "FET_REQUEST_COLOR";
export const FET_REQUEST_COLOR_SUCCESS = "FET_REQUEST_COLOR_SUCCESS";
export const FET_REQUEST_COLOR_FAIL = "FET_REQUEST_COLOR_FAIL";
export const CLEAR_REQUEST_COLOR = "CLEAR_REQUEST_COLOR";
export const FET_CITIES = "FET_CITIES";
export const FET_CITIES_SUCCESS = "FET_CITIES_SUCCESS";
export const FET_CITIES_FAIL = "FET_CITIES_FAIL";
export const FET_DISTRICTS = "FET_DISTRICTS";
export const FET_DISTRICTS_SUCCESS = "FET_DISTRICTS_SUCCESS";
export const FET_DISTRICTS_FAIL = "FET_DISTRICTS_FAIL";
export const FET_DISTRICT_BY_CITY = "FET_DISTRICT_BY_CITY";
export const FET_DISTRICT_BY_CITY_SUCCESS = "FET_DISTRICT_BY_CITY_SUCCESS";
export const FET_DISTRICT_BY_CITY_FAIL = "FET_DISTRICT_BY_CITY_FAIL";
export const FET_COLOR_REQUEST_DETAIL = "FET_COLOR_REQUEST_DETAIL";
export const FET_COLOR_REQUEST_DETAIL_SUCCESS =
  "FET_COLOR_REQUEST_DETAIL_SUCCESS";
export const FET_COLOR_REQUEST_DETAIL_FAIL = "FET_COLOR_REQUEST_DETAIL_FAIL";
export const UPDATE_STATUS_DETAIL_COLOR = "UPDATE_STATUS_DETAIL_COLOR";
export const UPDATE_STATUS_DETAIL_COLOR_SUCCESS =
  "UPDATE_STATUS_DETAIL_COLOR_SUCCESS";
export const UPDATE_STATUS_DETAIL_COLOR_FAIL =
  "UPDATE_STATUS_DETAIL_COLOR_FAIL";

export const UPDATE_DETAIL_COLOR = "UPDATE_DETAIL_COLOR";
export const UPDATE_DETAIL_COLOR_SUCCESS = "UPDATE_DETAIL_COLOR_SUCCESS";
export const UPDATE_DETAIL_COLOR_FAIL = "UPDATE_DETAIL_COLOR_FAIL";

export const FET_PRODUCTS = "FET_PRODUCTS";
export const FET_PRODUCTS_SUCCESS = "FET_PRODUCTS_SUCCESS";
export const FET_PRODUCTS_FAIL = "FET_PRODUCTS_FAIL";
export const FET_PRODUCT_GROUP = "FET_PRODUCT_GROUP";
export const FET_PRODUCT_GROUP_SUCCESS = "FET_PRODUCT_GROUP_SUCCESS";
export const FET_PRODUCT_GROUP_FAIL = "FET_PRODUCT_GROUP_FAIL";
export const FET_PRODUCT_TYPE = "FET_PRODUCT_TYPE";
export const FET_PRODUCT_TYPE_SUCCESS = "FET_PRODUCT_TYPE_SUCCESS";
export const FET_PRODUCT_TYPE_FAIL = "FET_PRODUCT_TYPE_FAIL";
export const DOWNLOAD_PRODUCT = "DOWNLOAD_PRODUCT";

export const GET_GROUP_USER_DETAIL = "GET_GROUP_USER_DETAIL";
export const GET_GROUP_USER_DETAIL_SUCCESS = "GET_GROUP_USER_DETAIL_SUCCESS";
export const GET_GROUP_USER_DETAIL_FAIL = "GET_GROUP_USER_DETAIL_FAIL";

export const GET_PRODUCT_DETAIL = "GET_PRODUCT_DETAIL";
export const GET_PRODUCT_DETAIL_SUCCESS = "GET_PRODUCT_DETAIL_SUCCESS";
export const GET_PRODUCT_DETAIL_FAIL = "GET_PRODUCT_DETAIL_FAIL";

export const GET_PRODUCT_GROUP = "GET_PRODUCT_GROUP";
export const GET_PRODUCT_GROUP_SUCCESS = "GET_PRODUCT_GROUP_SUCCESS";
export const GET_PRODUCT_GROUP_FAIL = "GET_PRODUCT_GROUP_FAIL";

export const GET_PRODUCT_TYPE = "GET_PRODUCT_TYPE";
export const GET_PRODUCT_TYPE_SUCCESS = "GET_PRODUCT_TYPE_SUCCESS";
export const GET_PRODUCT_TYPE_FAIL = "GET_PRODUCT_TYPE_FAIL";

export const GET_PRODUCT_GROUP_3D = "GET_PRODUCT_GROUP_3D";
export const GET_PRODUCT_GROUP_3D_SUCCESS = "GET_PRODUCT_GROUP_3D_SUCCESS";
export const GET_PRODUCT_GROUP_3D_FAIL = "GET_PRODUCT_GROUP_3D_FAIL";
export const GET_PRODUCT_ITEM_3D_SUCCESS = "GET_PRODUCT_ITEM_3D_SUCCESS";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL";

export const CLEAR_PRODUCT_DETAIL = "CLEAR_PRODUCT_DETAIL";

export const UPDATE_PRODUCT_DETAIL = "UPDATE_PRODUCT_DETAIL";
export const UPDATE_PRODUCT_DETAIL_SUCCESS = "UPDATE_PRODUCT_DETAIL_SUCCESS";
export const UPDATE_PRODUCT_DETAIL_FAIL = "UPDATE_PRODUCT_DETAIL_FAIL";

export const GET_PRODUCT_PROPERTIES = "GET_PRODUCT_PROPERTIES";
export const GET_PRODUCT_PROPERTIES_SUCESS = "GET_PRODUCT_PROPERTIES_SUCESS";
export const GET_PRODUCT_PROPERTIES_FAIL = "GET_PRODUCT_PROPERTIES_FAIL";

export const ADD_LIST_PACKAGE = "ADD_LIST_PACKAGE";
export const ADD_LIST_PACKAGE_SUCCESS = "ADD_LIST_PACKAGE_SUCCESS";
export const ADD_LIST_PACKAGE_FAIL = "ADD_LIST_PACKAGE_FAIL";

export const UPDATE_LIST_PACKAGE = "UPDATE_LIST_PACKAGE";
export const UPDATE_LIST_PACKAGE_SUCCESS = "UPDATE_LIST_PACKAGE_SUCCESS";
export const UPDATE_LIST_PACKAGE_FAIL = "UPDATE_LIST_PACKAGE_FAIL";

export const DELETE_PACKAGE_PRODUCT = "DELETE_PACKAGE_PRODUCT";
export const DELETE_PACKAGE_PRODUCT_SUCCESS = "DELETE_PACKAGE_PRODUCT_SUCCESS";
export const DELETE_PACKAGE_PRODUCT_FAIL = "DELETE_PACKAGE_PRODUCT_FAIL";

export const UPDATE_STATUS_PACKAGE = "UPDATE_STATUS_PACKAGE";
export const UPDATE_STATUS_PACKAGE_SUCCESS = "UPDATE_STATUS_PACKAGE_SUCCESS";
export const UPDATE_STATUS_PACKAGE_FAIL = "UPDATE_STATUS_PACKAGE_FAIL";

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const GET_TOTAL_NEWS = "GET_TOTAL_NEWS";
export const GET_TOTAL_NEWS_SUCCESS = "GET_TOTAL_NEWS_SUCCESS";
export const GET_TOTAL_NEWS_FAIL = "GET_TOTAL_NEWS_FAIL";

export const GET_TOTAL_DEALER = "GET_TOTAL_DEALER";
export const GET_TOTAL_DEALER_SUCCESS = "GET_TOTAL_DEALER_SUCCESS";
export const GET_TOTAL_DEALER_FAIL = "GET_TOTAL_DEALER_FAIL";

export const GET_TOTAL_MEMBER = "GET_TOTAL_MEMBER";
export const GET_TOTAL_MEMBER_SUCCESS = "GET_TOTAL_MEMBER_SUCCESS";
export const GET_TOTAL_MEMBER_FAIL = "GET_TOTAL_MEMBER_FAIL";

export const TOP5_INTERACTION = "TOP5_INTERACTION";
export const TOP5_INTERACTION_SUCCESS = "TOP5_INTERACTION_SUCCESS";
export const TOP5_INTERACTION_FAIL = "TOP5_INTERACTION_FAIL";

export const GET_APP_MODULE_INTERACTIVE = "GET_APP_MODULE_INTERACTIVE";
export const GET_APP_MODULE_INTERACTIVE_SUCCESS =
  "GET_APP_MODULE_INTERACTIVE_SUCCESS";
export const GET_APP_MODULE_INTERACTIVE_FAIL =
  "GET_APP_MODULE_INTERACTIVE_FAIL";

export const ONLINE_TIME = "ONLINE_TIME";
export const ONLINE_TIME_SUCCESS = "ONLINE_TIME_SUCCESS";
export const ONLINE_TIME_FAIL = "ONLINE_TIME_FAIL";

export const GET_DASHBOARD_STATISTIC = "GET_DASHBOARD_STATISTIC";
export const GET_DASHBOARD_STATISTIC_SUCCESS =
  "GET_DASHBOARD_STATISTIC_SUCCESS";
export const GET_DASHBOARD_STATISTIC_FAIL = "GET_DASHBOARD_STATISTIC_FAIL";

export const TOP5_GAMER_ONLINE = "TOP5_GAMER_ONLINE";
export const TOP5_GAMER_ONLINE_SUCCESS = "TOP5_GAMER_ONLINE_SUCCESS";
export const TOP5_GAMER_ONLINE_FAIL = "TOP5_GAMER_ONLINE_FAIL";

export const TOTAL_VIEW_NEWS = "TOTAL_VIEW_NEWS";
export const TOTAL_VIEW_NEWS_SUCCESS = "TOTAL_VIEW_NEWS_SUCCESS";
export const TOTAL_VIEW_NEWS_FAIL = "TOTAL_VIEW_NEWS_FAIL";

export const REQUEST_BH = "REQUEST_BH";
export const REQUEST_BH_SUCCESS = "REQUEST_BH_SUCCESS";
export const REQUEST_BH_FAIL = "REQUEST_BH_FAIL";

export const REQUEST_PH = "REQUEST_PH";
export const REQUEST_PH_SUCCESS = "REQUEST_PH_SUCCESS";
export const REQUEST_PH_FAIL = "REQUEST_PH_FAIL";

export const KTD = "KTD";
export const KTD_SUCCESS = "KTD_SUCCESS";
export const KTD_FAIL = "KTD_FAIL";

export const TOP3_NEWS = "TOP3_NEWS";
export const TOP3_NEWS_SUCCESS = "TOP3_NEWS_SUCCESS";
export const TOP3_NEWS_FAIL = "TOP3_NEWS_FAIL";
export const SHOW_EDIT_BUTTON = "SHOW_EDIT_BUTTON";
export const HIDE_EDIT_BUTTON = "HIDE_EDIT_BUTTON";

export const GET_NEWS = "GET_NEWS";
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS";
export const GET_NEWS_FAIL = "GET_NEWS_FAIL";

export const FETCH_LUCKY_DRAW_TRANSACTIONS = "FETCH_LUCKY_DRAW_TRANSACTIONS";
export const FETCH_LUCKY_DRAW_TRANSACTIONS_SUCCESS =
  "FETCH_LUCKY_DRAW_TRANSACTIONS_SUCCESS";
export const FETCH_LUCKY_DRAW_TRANSACTIONS_FAIL =
  "FETCH_LUCKY_DRAW_TRANSACTIONS_FAIL";
export const FETCH_LUCKY_DRAW_TRANSACTIONS_DETAIL =
  "FETCH_LUCKY_DRAW_TRANSACTIONS_DETAIL";
export const FETCH_LUCKY_DRAW_TRANSACTIONS_DETAIL_SUCCESS =
  "FETCH_LUCKY_DRAW_TRANSACTIONS_DETAIL_SUCCESS";
export const FETCH_LUCKY_DRAW_TRANSACTIONS_DETAIL_FAIL =
  "FETCH_LUCKY_DRAW_TRANSACTIONS_DETAIL_FAIL";
export const PUT_LUCKY_DRAW_TRANSACTIONS_STATUS =
  "PUT_LUCKY_DRAW_TRANSACTIONS_STATUS";
export const PUT_LUCKY_DRAW_TRANSACTIONS_STATUS_SUCCESS =
  "PUT_LUCKY_DRAW_TRANSACTIONS_STATUS_SUCCESS";
export const PUT_LUCKY_DRAW_TRANSACTIONS_STATUS_FAIL =
  "PUT_LUCKY_DRAW_TRANSACTIONS_STATUS_FAIL";
export const PUT_LUCKY_DRAW_TRANSACTIONS = "PUT_LUCKY_DRAW_TRANSACTIONS";
export const PUT_LUCKY_DRAW_TRANSACTIONS_SUCCESS =
  "PUT_LUCKY_DRAW_TRANSACTIONS_SUCCESS";
export const PUT_LUCKY_DRAW_TRANSACTIONS_FAIL =
  "PUT_LUCKY_DRAW_TRANSACTIONS_FAIL";
export const CLEAR_LUCKY_DRAW_TRANSACTIONS_DETAIL =
  "CLEAR_LUCKY_DRAW_TRANSACTIONS_DETAIL";
export const CLEAR_LUCKY_DRAW_TRANSACTIONS = "CLEAR_LUCKY_DRAW_TRANSACTIONS";

export const GET_TOTAL_POINT = "GET_TOTAL_POINT";
export const GET_TOTAL_POINT_SUCCESS = "GET_TOTAL_POINT_SUCCESS";
export const GET_TOTAL_POINT_FAIL = "GET_TOTAL_POINT_FAIL";

export const GET_POINT = "GET_POINT";
export const GET_POINT_SUCCESS = "GET_POINT_SUCCESS";
export const GET_POINT_FAIL = "GET_POINT_FAIL";

export const GET_PLAY_QUANTITY = "GET_PLAY_QUANTITY";
export const GET_PLAY_QUANTITY_SUCCESS = "GET_PLAY_QUANTITY_SUCCESS";
export const GET_PLAY_QUANTITY_FAIL = "GET_PLAY_QUANTITY_FAIL";

export const TOP3_PRODUCT_SCAN = "TOP3_PRODUCT_SCAN";
export const TOP3_PRODUCT_SCAN_SUCCESS = "TOP3_PRODUCT_SCAN_SUCCESS";
export const TOP3_PRODUCT_SCAN_FAIL = "TOP3_PRODUCT_SCAN_FAIL";

export const GET_PLAY_TIME = "GET_PLAY_TIME";
export const GET_PLAY_TIME_SUCCESS = "GET_PLAY_TIME_SUCCESS";
export const GET_PLAY_TIME_FAIL = "GET_PLAY_TIME_FAIL";

export const FETCH_LUCKY_DRAW_EVENTS = "FETCH_LUCKY_DRAW_EVENTS";
export const FETCH_LUCKY_DRAW_EVENTS_SUCCESS =
  "FETCH_LUCKY_DRAW_EVENTS_SUCCESS";
export const FETCH_LUCKY_DRAW_EVENTS_FAIL = "FETCH_LUCKY_DRAW_EVENTS_FAIL";

export const FETCH_LUCKY_DRAW_EVENTS_DETAIL = "FETCH_LUCKY_DRAW_EVENTS_DETAIL";
export const FETCH_LUCKY_DRAW_EVENTS_DETAIL_SUCCESS =
  "FETCH_LUCKY_DRAW_EVENTS_DETAIL_SUCCESS";
export const FETCH_LUCKY_DRAW_EVENTS_DETAIL_FAIL =
  "FETCH_LUCKY_DRAW_EVENTS_DETAIL_FAIL";
export const CLEAR_LUCKY_DRAW_EVENTS = "CLEAR_LUCKY_DRAW_EVENTS";
export const CLEAR_LUCKY_DRAW_EVENTS_DETAIL = "CLEAR_LUCKY_DRAW_EVENTS_DETAIL";

export const FET_SURVEY = "FET_SURVEY";
export const FET_SURVEY_SUCCESS = "FET_SURVEY_SUCCESS";
export const FET_SURVEY_FAIL = "FET_SURVEY_FAIL";

export const FET_SURVEY_DETAIL = "FET_SURVEY_DETAIL";
export const FET_SURVEY_DETAIL_SUCCESS = "FET_SURVEY_DETAIL_SUCCESS";
export const FET_SURVEY_DETAIL_FAIL = "FET_SURVEY_DETAIL_FAIL";

export const FETCH_SERVICE_NOTI_SUCCEESS = "FETCH_SERVICE_NOTI_SUCCEESS";
export const  FETCH_SERVICE_NOTI_FAILED = "FETCH_SERVICE_NOTI_FAILED";
export const  FETCH_UNREAD_SERVICE_NOTI_SUCCEESS = "FETCH_UNREAD_SERVICE_NOTI_SUCCEESS";
export const  FETCH_UNREAD_SERVICE_NOTI_FAILED = "FETCH_UNREAD_SERVICE_NOTI_FAILED";
export const  PUT_SEEN_SERVICE_NOTI_SUCCEESS = "PUT_SEEN_SERVICE_NOTI_SUCCEESS";
export const  PUT_SEEN_SERVICE_NOTI_FAILED = "PUT_SEEN_SERVICE_NOTI_FAILED";
export const  PUT_SEEN_ALL_SERVICE_NOTI_SUCCEESS = "PUT_SEEN_ALL_SERVICE_NOTI_SUCCEESS";
export const  PUT_SEEN_ALL_SERVICE_NOTI_FAILED = "PUT_SEEN_ALL_SERVICE_NOTI_FAILED";
export const  FETCH_SERVICE_NOTI_BY_ID_SUCCEESS = "FETCH_SERVICE_NOTI_BY_ID_SUCCEESS";
export const  FETCH_SERVICE_NOTI_BY_ID_FAILED = "FETCH_SERVICE_NOTI_BY_ID_FAILED";

export const UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS";
export const UPDATE_STATUS_SURVEY_SUCCESS = "UPDATE_STATUS_SURVEY_SUCCESS";
export const UPDATE_SURVEY_SUCCESS = "UPDATE_SURVEY_SUCCESS";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
export const CREATE_SURVEY_QUESTION_SUCCESS = "CREATE_SURVEY_QUESTION_SUCCESS";

export const FETCH_DASHBOARD_SCANNING = "FETCH_DASHBOARD_SCANNING";
export const FETCH_DASHBOARD_SCANNING_SUCCESS = "FETCH_DASHBOARD_SCANNING_SUCCESS";
export const FETCH_DASHBOARD_SCANNING_FAIL = "FETCH_DASHBOARD_SCANNING_FAIL";

export const FETCH_EVENT_TOTAL_ENTRY = "FETCH_EVENT_TOTAL_ENTRY";
export const FETCH_EVENT_TOTAL_ENTRY_SUCCESS = "FETCH_EVENT_TOTAL_ENTRY_SUCCESS";
export const FETCH_EVENT_TOTAL_ENTRY_FAIL = "FETCH_EVENT_TOTAL_ENTRY_FAIL";

export const FETCH_APP_MODULE_USAGE_TIME = "FETCH_APP_MODULE_USAGE_TIME";
export const FETCH_APP_MODULE_USAGE_TIME_SUCCESS = "FETCH_APP_MODULE_USAGE_TIME_SUCCESS";
export const FETCH_APP_MODULE_USAGE_TIME_FAIL = "FETCH_APP_MODULE_USAGE_TIME_FAIL";

export const FETCH_TOP5_SEARCH_PRODUCT = "FETCH_TOP5_SEARCH_PRODUCT";
export const FETCH_TOP5_SEARCH_PRODUCT_SUCCESS = "FETCH_TOP5_SEARCH_PRODUCT_SUCCESS";
export const FETCH_TOP5_SEARCH_PRODUCT_FAIL = "FETCH_TOP5_SEARCH_PRODUCT_FAIL";
