import * as actionType from "./../actions/actionTypes";

const initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionType.SHOW_MODAL:
      return {
        ...state,
        [action.typeVisible]: true,
      };
    case actionType.HIDE_MODAL:
      return {
        ...state,
        [action.typeVisible]: false,
      };

    case actionType.GET_PLAY_QUANTITY:
    case actionType.TOP3_NEWS:
    case actionType.KTD:
    case actionType.TOTAL_VIEW_NEWS:
    case actionType.GET_TOTAL_DEALER:
    case actionType.ADD_LIST_PACKAGE:
    case actionType.UPDATE_LIST_PACKAGE:
    case actionType.DELETE_PACKAGE_PRODUCT:
    case actionType.UPDATE_STATUS_PACKAGE:
    case actionType.GET_TOTAL_NEWS:
    case actionType.GET_TOTAL_MEMBER:
    case actionType.TOP5_INTERACTION:
    case actionType.TOP5_GAMER_ONLINE:
    case actionType.ONLINE_TIME:
    case actionType.REQUEST_BH:
    case actionType.REQUEST_PH:
    case actionType.UPDATE_PRODUCT_DETAIL:
    case actionType.GET_NEWS:
    case actionType.GET_TOTAL_POINT:
    case actionType.GET_POINT:
    case actionType.TOP3_PRODUCT_SCAN:
    case actionType.GET_PLAY_TIME:
    case actionType.GET_DASHBOARD_STATISTIC:
    case actionType.FETCH_DASHBOARD_SCANNING:
    case actionType.FETCH_EVENT_TOTAL_ENTRY:
    case actionType.FETCH_APP_MODULE_USAGE_TIME:
    case actionType.FETCH_TOP5_SEARCH_PRODUCT:
      return {
        ...state,
        [action.loadingType]: true,
      };

    case actionType.GET_PLAY_TIME_SUCCESS:
    case actionType.GET_PLAY_TIME_FAIL:
    case actionType.TOP3_PRODUCT_SCAN_FAIL:
    case actionType.TOP3_PRODUCT_SCAN_SUCCESS:
    case actionType.GET_PLAY_QUANTITY_FAIL:
    case actionType.GET_PLAY_QUANTITY_SUCCESS:
    case actionType.GET_POINT_FAIL:
    case actionType.GET_POINT_SUCCESS:
    case actionType.GET_TOTAL_POINT_SUCCESS:
    case actionType.GET_TOTAL_POINT_FAIL:
    case actionType.GET_NEWS_SUCCESS:
    case actionType.GET_NEWS_FAIL:
    case actionType.TOP3_NEWS_FAIL:
    case actionType.TOP3_NEWS_SUCCESS:
    case actionType.KTD_FAIL:
    case actionType.KTD_SUCCESS:
    case actionType.REQUEST_PH_FAIL:
    case actionType.REQUEST_PH_SUCCESS:
    case actionType.REQUEST_BH_FAIL:
    case actionType.REQUEST_BH_SUCCESS:
    case actionType.TOTAL_VIEW_NEWS_FAIL:
    case actionType.TOTAL_VIEW_NEWS_SUCCESS:
    case actionType.ONLINE_TIME_FAIL:
    case actionType.ONLINE_TIME_SUCCESS:
    case actionType.TOP5_INTERACTION_FAIL:
    case actionType.TOP5_INTERACTION_SUCCESS:
    case actionType.TOP5_GAMER_ONLINE_FAIL:
    case actionType.TOP5_GAMER_ONLINE_SUCCESS:
    case actionType.GET_TOTAL_MEMBER_SUCCESS:
    case actionType.GET_TOTAL_MEMBER_FAIL:
    case actionType.ADD_LIST_PACKAGE_SUCCESS:
    case actionType.ADD_LIST_PACKAGE_FAIL:
    case actionType.UPDATE_LIST_PACKAGE_SUCCESS:
    case actionType.UPDATE_LIST_PACKAGE_FAIL:
    case actionType.DELETE_PACKAGE_PRODUCT_SUCCESS:
    case actionType.DELETE_PACKAGE_PRODUCT_FAIL:
    case actionType.UPDATE_STATUS_PACKAGE_SUCCESS:
    case actionType.UPDATE_STATUS_PACKAGE_FAIL:
    case actionType.GET_TOTAL_NEWS_FAIL:
    case actionType.GET_TOTAL_NEWS_SUCCESS:
    case actionType.GET_TOTAL_DEALER_FAIL:
    case actionType.GET_TOTAL_DEALER_SUCCESS:
    case actionType.UPDATE_PRODUCT_DETAIL_FAIL:
    case actionType.UPDATE_PRODUCT_DETAIL_SUCCESS:
    case actionType.GET_DASHBOARD_STATISTIC_FAIL:
    case actionType.GET_DASHBOARD_STATISTIC_SUCCESS:
    case actionType.FETCH_DASHBOARD_SCANNING_SUCCESS:
    case actionType.FETCH_DASHBOARD_SCANNING_FAIL:
    case actionType.FETCH_EVENT_TOTAL_ENTRY_SUCCESS:
    case actionType.FETCH_EVENT_TOTAL_ENTRY_FAIL:
    case actionType.FETCH_APP_MODULE_USAGE_TIME_SUCCESS:
    case actionType.FETCH_APP_MODULE_USAGE_TIME_FAIL:
    case actionType.FETCH_TOP5_SEARCH_PRODUCT_SUCCESS:
    case actionType.FETCH_TOP5_SEARCH_PRODUCT_FAIL:
      return {
        ...state,
        [action.loadingType]: false,
      };

    case actionType.SHOW_EDIT_BUTTON:
      return {
        ...state,
        [action.editType]: true,
      };
    case actionType.HIDE_EDIT_BUTTON:
      return {
        ...state,
        [action.editType]: false,
      };

    default:
      return state;
  }
}
