let green = {
  id: 1, key: "1", color: "#43a047"
}
let dark_green = {
  id: 8, key: "8", color: "#00695c"
}
let yellow = {
  id: 2, key: "2", color: "#e0c901"
}
let blue = {
  id: 3, key: "3", color: "#29b6f6"
}
let light_blue = {
  id: 3, key: "3", color: "#42a5f5"
}
let grey = {
  id: 4, key: "4", color: "#959595"
}
let red = {
  id: 5, key: "5", color: "#e53935"
}
let orange = {
  id: 6, key: "6", color: "#f57c00"
}
let purple = {
  id: 7, key: "7", color: "#ab47bc"
}
let black = {
  id: 8, key: "8", color: "#000"
}

export {
  green,
  yellow,
  blue,
  light_blue,
  grey,
  red,
  orange,
  purple,
  dark_green,
  black
}