import {produce} from 'immer';

const UserReducer = produce((draft = [], action = {}) => {
  switch(action.type){
    case "FETCH_USERS_SUCCESS":
      draft = action.payload
      return draft
    default: 
      return draft
  }
})

export default UserReducer