import produce from "immer"

const initState = localStorage.auth && JSON.parse(localStorage.auth) ? JSON.parse(localStorage.auth) : {}

const LoggedUser = produce((draft = initState, action={}) => {
    switch (action.type) {
        case "LOGIN_SUCCESS":
            draft = action.payload
            return draft
        case "LOGOUT_SUCCESS":
            draft = {}
            localStorage.removeItem("auth")
            return draft
        default:
            return draft
    }
})

export default LoggedUser