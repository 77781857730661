import {produce} from 'immer';

const GroupProductReducer = produce((draft = [], action = {}) => {
  switch(action.type){
    case "FETCH_GROUP_PRODUCT_SUCCESS":
      draft = action.payload
      return draft
    default: 
      return draft
  }
})

export default GroupProductReducer