import produce from "immer"
import * as actionTypes from '../actions/actionTypes'
const initState = {
  loading: false,
  data: [],
  error: undefined
}

const ModuleInteractiveReducer = produce((draft = initState, action={}) => {
    switch (action.type) {
        case actionTypes.GET_APP_MODULE_INTERACTIVE:
          draft.loading = true
          draft.error = undefined
          return draft
        case actionTypes.GET_APP_MODULE_INTERACTIVE_SUCCESS:
          draft.data = action.payload
          draft.loading = false
          return draft
        case actionTypes.GET_APP_MODULE_INTERACTIVE_FAIL:
          draft.loading = false
          draft.error = action.payload
          return draft
        default:
            return draft
    }
})

export default ModuleInteractiveReducer