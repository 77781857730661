import * as actionTypes from "./../actions/actionTypes";
const initialState = {
  data: [],
};
const ProductType = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FET_PRODUCT_TYPE:
      return { ...state };
    case actionTypes.FET_PRODUCT_TYPE_SUCCESS:
      state.data = action.payload.data.data;
      return { ...state };
    case actionTypes.FET_PRODUCT_TYPE_FAIL:
      return { ...state };
    default:
      return { ...state };
  }
};
export default ProductType;
