import {produce} from 'immer';

const CityReducer = produce((draft = [], action = {}) => {
  switch(action.type){
    case "FETCH_CITIES_SUCCESS":
      draft = action.payload
      return draft
    default: 
      return draft
  }
})

export default CityReducer