import { takeLatest, all, fork, take, takeEvery } from "redux-saga/effects";
import * as sagas from "./saga-actions";
import * as actionTypes from "./actionTypes";

export default function* rootSaga() {
  // let token = yield localStorage.auth && JSON.parse(localStorage.auth).token ? JSON.parse(localStorage.auth).token : null
  // if(!token){
  //   yield take('LOGIN_SUCCESS')
  //   token = yield JSON.parse(localStorage.auth).token
  // }
  // if(token){
  yield all([
    takeLatest("FETCH_MEMBER", sagas.fetchMember),
    takeLatest("FETCH_CITIES", sagas.fetchCities),
    takeLatest("FETCH_USERS", sagas.fetchUsers),
    takeLatest("FETCH_GROUP_USERS", sagas.fetchGroupUsers),
    takeLatest("FETCH_NOTIFICATIONS", sagas.fetchNotifications),
    takeLatest(actionTypes.FET_REQUEST_COLOR, sagas.fetColorRequest),
    takeLatest(actionTypes.FET_CITIES, sagas.fetCities),
    takeLatest(actionTypes.FET_DISTRICTS, sagas.fetDistrict),
    takeLatest(actionTypes.FET_DISTRICT_BY_CITY, sagas.fetDistrictByCityId),
    takeLatest(
      actionTypes.FET_COLOR_REQUEST_DETAIL,
      sagas.fetColorRequestDetail,
    ),
    takeLatest(
      actionTypes.UPDATE_STATUS_DETAIL_COLOR,
      sagas.updateStatusColorRequest,
    ),
    takeLatest(actionTypes.UPDATE_DETAIL_COLOR, sagas.putColorRequest),
    //van dai product list
    takeLatest(actionTypes.FET_PRODUCTS, sagas.fetProducts),
    takeLatest(actionTypes.FET_PRODUCT_GROUP, sagas.fetProductGroup),
    takeLatest(actionTypes.FET_PRODUCT_TYPE, sagas.fetProductType),
    //end van dai

    takeLatest(actionTypes.GET_GROUP_USER_DETAIL, sagas.getGroupUserDetailSaga),

    takeLatest(actionTypes.GET_PRODUCT_DETAIL, sagas.getProductDetailSaga),
    takeLatest(actionTypes.GET_PRODUCT_GROUP, sagas.getGroupProduct),
    takeLatest(actionTypes.GET_PRODUCT_TYPE, sagas.getProductTypeSaga),
    takeLatest(actionTypes.GET_PRODUCT_GROUP_3D, sagas.getProductGroup3D),
    takeLatest(actionTypes.ADD_PRODUCT, sagas.addProductSaga),
    takeLatest(actionTypes.UPDATE_PRODUCT_DETAIL, sagas.updateProductDetail),
    takeLatest(actionTypes.GET_PRODUCT_PROPERTIES, sagas.getProductProperties),
    takeLatest(actionTypes.ADD_LIST_PACKAGE, sagas.addPackageList),
    takeLatest(actionTypes.UPDATE_LIST_PACKAGE, sagas.updatePackageList),
    takeLatest(actionTypes.DELETE_PACKAGE_PRODUCT, sagas.deletePackageProduct),
    takeLatest(actionTypes.UPDATE_STATUS_PACKAGE, sagas.updateStatusPackage),
    takeLatest("FETCH_ARTICLE", sagas.fetchArticle),
    takeLatest("PUT_ARTICLE", sagas.putArticle),
    takeLatest("PUT_ARTICLE_RELATED_INFO", sagas.putArticle),
    takeLatest("PUT_ARTICLE_STATUS", sagas.putArticleStatus),

    takeLatest(actionTypes.GET_TOTAL_NEWS, sagas.getTotalNews),
    takeLatest(actionTypes.GET_TOTAL_DEALER, sagas.getTotalDealer),
    takeLatest(actionTypes.GET_TOTAL_MEMBER, sagas.getTotalMember),
    takeLatest(actionTypes.TOP5_INTERACTION, sagas.getTop5Interaction),
    takeLatest(actionTypes.ONLINE_TIME, sagas.getOnlineTime),
    takeLatest(actionTypes.TOP5_GAMER_ONLINE, sagas.getTop5GamerOnline),
    takeLatest(actionTypes.TOTAL_VIEW_NEWS, sagas.getTotalViewNews),
    takeLatest(actionTypes.REQUEST_BH, sagas.getTotalRequestBH),
    takeLatest(actionTypes.REQUEST_PH, sagas.getTotalRequestPM),
    takeLatest(actionTypes.KTD, sagas.getNewsKTD),
    takeLatest(actionTypes.TOP3_NEWS, sagas.getTop3News),
    takeLatest(actionTypes.GET_NEWS, sagas.getNews),
    takeLatest(
      actionTypes.GET_APP_MODULE_INTERACTIVE,
      sagas.getAppModuleInteractive,
    ),
    takeLatest(actionTypes.GET_DASHBOARD_STATISTIC, sagas.getDashboarStatistic),

    takeLatest(
      actionTypes.FETCH_LUCKY_DRAW_TRANSACTIONS,
      sagas.fetchLuckyDrawTrans,
    ),
    takeLatest(
      actionTypes.FETCH_LUCKY_DRAW_TRANSACTIONS_DETAIL,
      sagas.fetchLuckyDrawTransDetail,
    ),
    takeEvery(
      actionTypes.PUT_LUCKY_DRAW_TRANSACTIONS_STATUS,
      sagas.putLuckyDrawTransStatus,
    ),
    takeEvery(actionTypes.PUT_LUCKY_DRAW_TRANSACTIONS, sagas.putLuckyDrawTrans),

    takeLatest(actionTypes.GET_TOTAL_POINT, sagas.loadTotalPoint),
    takeLatest(actionTypes.GET_POINT, sagas.loadPoint),
    takeLatest(actionTypes.GET_PLAY_QUANTITY, sagas.loadPlayQuantity),
    takeLatest(actionTypes.TOP3_PRODUCT_SCAN, sagas.loadTop3ProductScan),
    takeLatest(actionTypes.GET_PLAY_TIME, sagas.getPlayTime),
    takeLatest(actionTypes.FETCH_LUCKY_DRAW_EVENTS, sagas.fetchLuckyDrawEvents),
    takeLatest(
      actionTypes.FETCH_LUCKY_DRAW_EVENTS_DETAIL,
      sagas.fetchLuckyDrawEventDetail,
    ),
    takeLatest(actionTypes.FET_SURVEY, sagas.fetchSurveys),
    takeLatest(actionTypes.FET_SURVEY_DETAIL, sagas.fetchSurveyDetail),
    takeLatest(actionTypes.FETCH_EVENT_TOTAL_ENTRY, sagas.fetchEventTotalEntry),
    takeLatest(actionTypes.FETCH_APP_MODULE_USAGE_TIME, sagas.fetchAppModuleUsageTime),
    takeLatest(actionTypes.FETCH_DASHBOARD_SCANNING, sagas.fetchDashboardScanning),
    takeLatest(actionTypes.FETCH_TOP5_SEARCH_PRODUCT, sagas.fetchTop5SearchProduct),

    takeLatest('FETCH_SERVICE_NOTI', sagas.fetchServiceNoti),
    takeLatest('FETCH_UNREAD_SERVICE_NOTI', sagas.fetchUnreadServiceNoti),
    takeLatest('FETCH_SERVICE_NOTI_BY_ID', sagas.fetchServiceNotiById),
    takeLatest('PUT_SEEN_SERVICE_NOTI', sagas.putSeenServiceNoti),
    takeLatest('PUT_SEEN_ALL_SERVICE_NOTI', sagas.postSeenAllServiceNoti),
  ]);
  // }
}
