import * as actionTypes from "./../actions/actionTypes";
const initialState = {
  data: [],
};
const ProductGroup = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FET_PRODUCT_GROUP:
      return { ...state };
    case actionTypes.FET_PRODUCT_GROUP_SUCCESS:
      state.data = action.payload.data.data;
      return { ...state };
    case actionTypes.FET_PRODUCT_GROUP_FAIL:
      return { ...state };
    default:
      return { ...state };
  }
};
export default ProductGroup;
