import produce from "immer"
import {message} from 'antd'

const initState = {
  loading: false,
  submiting: false,
  data: undefined,
  error: undefined
}
const ArticleReducer = produce((draft = initState, action={}) => {
  switch (action.type) {
    case "FETCH_ARTICLE":
      draft.loading = true
      return draft
    case "FETCH_ARTICLE_SUCCESS":
      draft.loading = false
      draft.data = action.payload
      return draft
    case "PUT_ARTICLE":
      draft.submiting = true
      return draft
    case "PUT_ARTICLE_RELATED_INFO":
      draft.submiting = true
      return draft
    case "PUT_ARTICLE_SUCCESS":
      draft.data = action.payload
      draft.submiting = false
      if(action.typeName){
        message.success(`Cập nhật ${action.typeName} thành công!`)
      }
      else{
        message.success("Cập nhật thông tin bài viết thành công!")
      }
      return draft
    case "PUT_ARTICLE_FAILED":
      draft.error = action.payload
      message.error("Cập nhật thông tin bài viết thất bại!")
      return draft
    case "PUT_ARTICLE_STATUS":
      draft.submiting = true
      return draft
    case "PUT_ARTICLE_STATUS_SUCCESS":
      draft.data = action.payload
      draft.submiting = false
      message.success("Cập nhật trạng thái thành công!")
      return draft
    case "PUT_ARTICLE_STATUS_FAILED":
      draft.error = action.payload
      message.error("Cập nhật trạng thái thất bại!")
      return draft
    case "FETCH_ARTICLE_FAILED":
      draft.error = action.payload
      return draft
    case "CLEAR_ARTICLE":
      draft = {...initState}
      return draft
    default:
      return draft
  }
})

export default ArticleReducer;