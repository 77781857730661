import { combineReducers } from "redux";
import MemberReducer from "./MemberReducer";
import CityReducer from "./CityReducer";
import LoggedUserReducer from "./LoggedUserReducer";
import UserReducer from "./UserReducer";
import GroupUserReducer from "./GroupUserReducer";
import PromotionReducer from "./PromotionReducer";
import GroupProductReducer from "./GroupProductReducer";
import NotificationReducer from "./NotificationReducer";
import ColorRequests from "./ColorRequestsReducer";
import Cities from "./Cities";
import Districts from "./Districts";
import ColorRequestReducer from "./ColorRequestReducer";
import Products from "./Products";
import ProductGroup from "./ProductGroup";
import ProductType from "./ProductType";
import ProductReducer from "./ProductReducer";
import UIReducer from "./UIReducer";
import ArticleReducer from "./ArticleReducer";
import DashboardReducer from "./DashboardReducer";
import luckyDrawTrans from "./LuckyDrawTransReducer";
import luckyDrawEvent from "./LuckyDrawEventReducer";
import ModuleInteractiveReducer from "./ModuleInteractiveReducer";
import Surveys from "./Surveys";
import ServiceNotifyReducer from './ServiceNotifyReducer';

const reducers = combineReducers({
  member: MemberReducer,
  city: CityReducer,
  loggedUser: LoggedUserReducer,
  users: UserReducer,
  groupUsers: GroupUserReducer,
  promotions: PromotionReducer,
  groupProducts: GroupProductReducer,
  notifications: NotificationReducer,
  colorRequests: ColorRequests,
  Cities,
  colorRequest: ColorRequestReducer,
  Districts,
  Products,
  ProductGroup,
  ProductType,
  ProductReducer,
  UIReducer,
  article: ArticleReducer,
  DashboardReducer,
  luckyDrawTrans,
  luckyDrawEvent,
  moduleInteractive: ModuleInteractiveReducer,
  surveys: Surveys,
  serviceNoti: ServiceNotifyReducer,
});

export default reducers;
