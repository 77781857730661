import * as actionTypes from "../actions/actionTypes";
import { unaccent } from "./../utils";
const initialState = {
  loading: false,
  cities: [],
};
const Cities = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FET_CITIES:
      return { ...state };
    case actionTypes.FET_CITIES_SUCCESS:
      state.cities = action.payload.data.data;
      return { ...state };
    case actionTypes.FET_CITIES_FAIL:
      state.loading = false;
      return { ...state };
    default:
      return { ...state };
  }
};
export default Cities;
