import React, { useState, Suspense, useEffect } from "react";
import { Table, Drawer, Button, Spin, Tag } from "antd";
import * as actionTypes from "./../../actions/actionTypes";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ColorRequestFilter from "./component/ColorRequestFilter";
import { useLocation, useHistory } from "react-router-dom";
import { routerChange } from "./../../utils/urlChange";
import { EyeOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment-mini";
import queryString from "query-string";
import {fetColorRequestFromApi} from "../../actions/api"
import FileDownload from 'js-file-download'
import { renderURL } from "../../utils";

let initFilter = {
  page: 1,
  perPage: 10
}

const Dealers = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const history = useHistory();
  let urlParam = {...initFilter, ...queryString.parse(location.search)};
  const [exporting, setExport] = useState(false)
  
  const rederStatusMember = (record) => {
    if(record && record.member_create_info && record.member_create_info.id){
      return (
        // <Link to={`/members/${record.member_create_info.id}`}>
          <span>{record && record.member_create_info && record.member_create_info.name ? record.member_create_info.name : null}</span>
        // </Link>
      );
    }
    return null
  };

  const onChangeSize = (page, pageSize) => {
    let url = history.location.search.toString();
    if (!url) {
      let param = {
        perPage: 10,
        page: page.current,
      };
      // url = "?perPage=10&page=" + page.current;
      // routerChange(history, location.pathname, param);
      props.fetColorRequest(param);
    } else {
      let objTemp = queryString.parse(url);
      objTemp.page = page.current;
      // routerChange(history, location.pathname, objTemp);
      // let url2 = history.location.search.toString();
      props.fetColorRequest(objTemp);
    }
  };
  const resetCurrentPage = (number) => {
    setCurrentPage(number);
  };
  const renderStatus = (status) => {
    if (status === -1) {
      return <Tag color="#e53935">Đã xóa</Tag>;
    }
    if (status === 1) {
      return <Tag color="#29b6f6">Mới tạo</Tag>;
    }
    if (status === 2) {
      return <Tag color="#ab47bc">Đợi duyệt</Tag>;
    }
    if (status === -2) {
      return <Tag color="#e53935">Đã hủy</Tag>;
    }
    if (status === 3) {
      return <Tag color="#43a047">Đã duyệt</Tag>;
    }
    if (status === -3) {
      return <Tag color="#959595">Đã từ chối</Tag>;
    }
    if (status === 4) {
      return <Tag color="#00695c">Hoàn thành</Tag>;
    }
  };
  const columns = [
    {
      title: "ID",
      key: "id",
      render: (text, record) => (
        <Link to={`/color-requests/${record.id}`}>
          <p>{record?.id}</p>
        </Link>
      ),
    },
    {
      title: "Tên người sử dụng app",
      key: "name",
      render: (text, record) => rederStatusMember(record),
    },
    {
      title: "Người liên hệ",
      key: "contact_name",
      render: (text, record) => record && record.member_request ? record.member_request.name : "",
    },
    {
      title: "Email",
      key: "email",
      render: (text, record) => <p>{record?.member_request?.email}</p>,
    },
    {
      title: "Số điện thoại",
      key: "phone",
      render: (text, record) => <p>{record?.member_request?.phone}</p>,
    },
    {
      title: "Địa chỉ",
      key: "location",
      render: (text, record) => (
        <p className="mb-0">
          <span>{record.address && record.address !== '""' ? record.address : ""}</span>
          <span className="mb-1">{record.ward && record.ward !== '""' ? ", " + record.ward : ""}</span>
          <span className="mb-1">{record.district_info && record.district_info.name && record.district_info.name !== "''" ? ", " + record.district_info.name : ""}</span>
          <span className="mb-1">{record.city_info && record.city_info.name && record.city_info.name !== "''" ? ", " + record.city_info.name : ""}</span>
        </p>
      )
    },
    // {
    //   title: "Quận",
    //   //key: "district_info",
    //   render: (text, record) => <p>{record?.district_info?.name}</p>,
    // },
    // {
    //   title: "Thành phố/Tỉnh",
    //   //key: "city_info",
    //   render: (text, record) => <p>{record?.city_info?.name}</p>,
    // },
    // {
    //   title: "Mã màu",
    //   //key: "city_info",
    //   render: (text, record) => <p>{record?.request_info?.colors_info}</p>,
    // },
    {
      title: "Trạng thái",
      key: "status",
      render: (text, record) => renderStatus(record?.status),
    },
    {
      title: "Ngày tạo",
      key: "created_at",
      render: (text, record) =>
        moment(record?.created_at).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Hành động",
      render: (text, record) => (
        <Link to={`/color-requests/${record.id}`}>
          <Button type="primary" icon={<EyeOutlined />}>
          </Button>
        </Link>
      ),
    },
  ];

  const onExport = () => {
    setExport(true)
    const token = localStorage.auth && JSON.parse(localStorage.auth) ? JSON.parse(localStorage.auth).token : null;
    if(token){
      urlParam.link = location.pathname
      fetColorRequestFromApi({...initFilter,...urlParam, download: true, token }, 'blob').then(res => {
        FileDownload(res.data, moment().format("YYYYMMDD-HHmmss")+"_Consumer-SupportRequest.xlsx")
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        setExport(false)
      })
      // window.location.href = process.env.REACT_APP_CRM_API + "support-requests?" + renderURL("", {...initFilter,...urlParam, download: true, token }, "YYYY-MM-DD")
    }
  }

  return (
    <div
      style={{ marginTop: "20px" }}
      className="layout-container members-container"
    >
      <div className="d-flex align-items-center justify-content-between my-3">
        <p
          className="font-weight-bold mb-0"
          style={{ color: `#465f7b`, fontSize: `16px` }}
        >
          Danh sách yêu cầu phối màu
        </p>
        <div className="mb-1 actions d-flex align-items-center justify-content-end">
          <Button loading={exporting} onClick={onExport} type="default" className="mr-0" icon={<DownloadOutlined />}>Xuất dữ liệu</Button>
        </div>
      </div>
      <ColorRequestFilter
        initFilter={{...urlParam}}
        currentPage={currentPage}
        resetCurrentPage={resetCurrentPage}
      />

      <Table
        className="mt-3"
        dataSource={props.colorRequests.data}
        columns={columns}
        rowKey={(record) => record.id}
        bordered
        loading={props.colorRequests && props.colorRequests.loading ? props.colorRequests.loading : false}
        pagination={{
          total: props.colorRequests.total ? props.colorRequests.total : 0,
          pageSize: 10,
          position: ["topRight", "bottomRight"],
          showSizeChanger: false,
          responsive: true,
          current: urlParam.page*1,
          showTotal: (total, range) => {
            return (
              <span>
                {total} Yêu cầu | Từ {range[0]} đến {range[1]}
              </span>
            );
          },
          onChange: () => {},
        }}
        onChange={onChangeSize}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    colorRequests: state.colorRequests,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetColorRequest: (param) => {
      dispatch({
        type: actionTypes.FET_REQUEST_COLOR,
        payload: param,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dealers);
