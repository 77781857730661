import React from 'react';
import {Form, Input, Row, Col} from 'antd';
import classnames from 'classnames';
import {differentObject} from '../../utils'

const FilterFormItem = ({renderFilter, form, wrapperCol={xs: 24, sm: 12, md: 6, lg: 4, xxl: 3}}) => {
  const onChangeHandle = (value, option) => {
    if(!value) form.submit()
  }
  const renderFormItem = (items) => {
    return items.map(item => {
      if(item.render) {
        return (
          <Col {...wrapperCol} key={item.name} >
            <Form.Item initialValue={item.initValue} className={classnames(item.className)} name={item.name}>
              {item.render(onChangeHandle)}
            </Form.Item>
          </Col>
        )
      }
      return (
        <Col {...wrapperCol} key={item.name} >
          <Form.Item key={item.name} className={classnames(item.className)} name={item.name}>
            <Input placeholder={item.placeholder}/>
          </Form.Item>
        </Col>
      )
    })
  }

  return (
    <Row className="mb-3">
      {renderFormItem(renderFilter || [])}
    </Row>
  );
};

export default FilterFormItem;
