import { FETCH_SERVICE_NOTI_SUCCEESS, FETCH_SERVICE_NOTI_BY_ID_SUCCEESS, PUT_SEEN_SERVICE_NOTI_SUCCEESS, PUT_SEEN_ALL_SERVICE_NOTI_SUCCEESS, FETCH_UNREAD_SERVICE_NOTI_SUCCEESS } from '../actions/actionTypes';
import produce from 'immer'
import _ from 'lodash'

const initState = {
  data: [],
  total: 0,
  noti: {},
  error: false,
  loading: null,
  unreadNoti: 0,
}

const ServiceNotifyReducer = (state = initState, action = {}) => {
  return produce(state, draft => {
    switch(action.type){
      case "FETCH_SERVICE_NOTI":
        if(draft.data.length === 0){
          draft.loading = "first load"
        }
        else{
          draft.loading = "load more"
        }
        return draft
      case FETCH_SERVICE_NOTI_SUCCEESS:
        if(draft.data.length === 0){
          draft.data = action.payload.datas
          draft.total = action.payload.total
          draft.loading = null
        }
        else{
          if(!action.filter.after){
            draft.data = [...(_.unionBy(action.payload.datas, draft.data, 'id'))]
          }
          else{
            draft.data = [...draft.data, ...action.payload.datas]
          }
          draft.total = action.payload.total
          draft.loading = null
        }
        return draft
      case FETCH_SERVICE_NOTI_BY_ID_SUCCEESS:
        draft.data.unshift(action.payload.data)
        return draft
      case PUT_SEEN_SERVICE_NOTI_SUCCEESS:
        draft.data.map(n => {
          if(n.notify_item_id === action.payload.notify_id){
            n.is_unread = 2
          }
          return n
        })
        return draft
      case PUT_SEEN_ALL_SERVICE_NOTI_SUCCEESS:
        draft.data.map(n => {
          n.is_unread = 2
          return n
        })
        return draft
      case "CLEAR_NEW_NOTI_ICON":
        draft.unreadNoti = false
        return draft
      case FETCH_UNREAD_SERVICE_NOTI_SUCCEESS:
        draft.unreadNoti = action.payload.datas.length
        return draft
      case "CLEAR_SERVICE_NOTI":
        draft = initState
        return draft
      default:
        return draft
    }
  })
}

export default ServiceNotifyReducer;