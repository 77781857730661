import * as actionTypes from "../actions/actionTypes";
const initialState = {
  loading: false,
  districts: [],
};
const Districts = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FET_DISTRICTS:
      return { ...state };
    case actionTypes.FET_DISTRICTS_SUCCESS:
      state.districts = action.payload.data.data;
      return { ...state };
    case actionTypes.FET_DISTRICTS_FAIL:
      state.loading = false;
      return { ...state };
    case actionTypes.FET_DISTRICT_BY_CITY:
      state.loading = false;
      return { ...state };
    case actionTypes.FET_DISTRICT_BY_CITY_SUCCESS:
      state.loading = false;
      state.districts = action.payload.data.data.data;
      return { ...state };
    case actionTypes.FET_DISTRICT_BY_CITY_FAIL:
      state.loading = false;
      return { ...state };
    default:
      return { ...state };
  }
};
export default Districts;
