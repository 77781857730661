import React, {Suspense} from 'react';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css';
import './App.css';
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import {Spin} from 'antd';
import history from './history';
import ErrorBoundary from './ErrorBoundary'
import {Pushwoosh} from 'web-push-notifications';

const DefaultLayout = React.lazy(() => import('./Layout/DefaultLayout'));
const Login = React.lazy(() => import('./Layout/Login'));

window.pwInstance = new Pushwoosh();
window.pwInstance.push(['init', {
  // logLevel: 'info', // or debug
  applicationCode: process.env.REACT_APP_PUSH_WOOSH_ID,
  // safariWebsitePushID: 'web.com.example.domain',
  defaultNotificationTitle: 'Pushwoosh',
  // defaultNotificationImage: 'https://yoursite.com/img/logo-medium.png',
  autoSubscribe: false,
  device_type: 11,
  // subscribeWidget: {
  //   enable: true,
  // },
  // serviceWorkerUrl: './registerServiceWorker.js'
  // userId: 'user_id',
  // tags: {
  //     'Name': 'John Smith'
  // }
}]);

const AppRoute = () => {
  return (
    // <ErrorBoundary fallback={<h1 style={{textAlign:"center", marginTop: "15px"}}>Không thể kết nối tới máy chủ</h1>}>
      <Suspense fallback={<div className="loading-component"><Spin size="large" tip="Loading..."/></div>}>
        <Router>
          <Switch>
            <Redirect exact from="/" to="/login" />
            <Route exact path="/login" component={Login} />
            <Route path="/" component={DefaultLayout} />
          </Switch>
        </Router>
      </Suspense>
    // </ErrorBoundary>
  )
}

function App() {
  return (
    <div className="App">
      <AppRoute />
    </div>
  );
}

export default App;
