import * as actionTypes from "../actions/actionTypes";
import {produce} from 'immer'
import _ from 'lodash';
import { message } from "antd";

const initialState = {
  loading: false,
  data: undefined,
  transaction: undefined,
  error: false
};

const LuckyDrawTransReducer = produce((state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_LUCKY_DRAW_TRANSACTIONS:
      state.loading = true
      state.error = false
      return state
    case actionTypes.FETCH_LUCKY_DRAW_TRANSACTIONS_DETAIL:
      state.loading = true
      state.error = false
      return state
    case actionTypes.FETCH_LUCKY_DRAW_TRANSACTIONS_SUCCESS:
      state.loading = false
      state.data = action.payload
      return state;
    case actionTypes.FETCH_LUCKY_DRAW_TRANSACTIONS_FAIL:
      state.loading = false;
      state.error = true
      return state;
    
    case actionTypes.FETCH_LUCKY_DRAW_TRANSACTIONS_DETAIL_SUCCESS:
      state.transaction = {
        ...action.payload,
        listLog: formatLogs(action.payload?.logs || {}),
      }
      state.loading = false
      return state;
    case actionTypes.FETCH_LUCKY_DRAW_TRANSACTIONS_DETAIL_FAIL:
      state.loading = false
      state.error = true
      return state;

    case actionTypes.PUT_LUCKY_DRAW_TRANSACTIONS:
      state.submitting = true
      return state
    case actionTypes.PUT_LUCKY_DRAW_TRANSACTIONS_SUCCESS:
      message.success("Cập nhật thành công!")
      state.transaction = {
        ...action.payload,
        listLog: formatLogs(action.payload.logs),
      }
      state.submitting = false
      return state
    case actionTypes.PUT_LUCKY_DRAW_TRANSACTIONS_FAIL:
      message.error("Cập nhật thất bại!")
    //   state.error = true
      state.submitting = false
      return state
    case actionTypes.PUT_LUCKY_DRAW_TRANSACTIONS_STATUS:
      state.submitting = true
      return state
    case actionTypes.PUT_LUCKY_DRAW_TRANSACTIONS_STATUS_SUCCESS:
      // if(state?.data?.data && state.data.data.length > 0){
      //   action.payload.loading = false
      //   updateRecord(state.data.data, action.payload)
      // }
      state.transaction = {
        ...action.payload,
        listLog: formatLogs(action.payload.logs),
      }
      state.submitting = false
      message.success("Cập nhật trạng thái thành công!")
      return state
    case actionTypes.PUT_LUCKY_DRAW_TRANSACTIONS_STATUS_FAIL:
    //   if(state?.data?.data && state.data.data.length > 0){
    //     updateRecord(state.data.data, {loading: false})
    //   }
      state.submitting = false
      message.error("Cập nhật trạng thái thất bại!")
      return state
    case actionTypes.CLEAR_LUCKY_DRAW_TRANSACTIONS_DETAIL:
      state = initialState
      return state
    case actionTypes.CLEAR_LUCKY_DRAW_TRANSACTIONS:
      state = initialState
      return state
    default:
      return state;
  }
});
export default LuckyDrawTransReducer;

const formatLogs = (logs) => {
  let temp = [];
  if(!logs.list || logs.list.length === 0){
    return temp
  }
  for (let i = 0; i < logs.list.length; i++) {
    let item = logs.list[i];
    if(item.action === "edit"){
      temp.push(item)
    }
    else{
      if (item.action === 1) {
        temp.push({
          ...item,
          classColor: "arrow-status-1",
        });
      }
      if (item.action === 2) {
        temp.push({
          ...item,
          classColor: "arrow-status-2",
        });
      }
      if (item.action === 3) {
        temp.push({
          ...item,
          classColor: "arrow-status-4",
        });
      }
      if (item.action === 4) {
        temp.push({
          ...item,
          classColor: "arrow-status-10",
        });
      }
      if (item.action === 5) {
        temp.push({
          ...item,
          classColor: "arrow-status-20",
        });
      }
    }
  }
  return temp
}

const updateRecord = (currentData, newData) => {
  let itemIndex = _.findIndex(currentData, d => d.id * 1 === newData.id * 1)
  let transaction = currentData[itemIndex]
  if(transaction?.id){
    if(newData.status){
      transaction.status = newData.status
    }
    transaction.loading = newData.loading
    currentData[itemIndex] = transaction
  }
  return currentData
}