import * as actionTypes from "../actions/actionTypes";
import ColorRequests from "../container/ColorRequest/ColorRequests";
import {produce} from 'immer'
const initialState = {
  loading: false,
  data: undefined,
  error: undefined,
  submiting: false
};
const ColorRequestDetail = produce((state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FET_COLOR_REQUEST_DETAIL:
      state.loading = true;

      return state;
    case actionTypes.FET_COLOR_REQUEST_DETAIL_SUCCESS:
      state.loading = false;
      state.submiting = false;
      state.data = {
        ...action.payload.data,
        listLog: formatLogs(action.payload.data.logs),
      };
      state.data = formatLocation(state.data)
      return state
    case actionTypes.FET_COLOR_REQUEST_DETAIL_FAIL:
      state.loading = false;
      state.error = action.payload
      return state;

    case actionTypes.UPDATE_STATUS_DETAIL_COLOR:
      state.submiting = true;
      return state;

    case actionTypes.UPDATE_DETAIL_COLOR:
      state.submiting = true
      return state
    
    case actionTypes.UPDATE_DETAIL_COLOR_SUCCESS:
      state.submiting = false;
      state.data = {
        ...action.payload.data,
        listLog: formatLogs(action.payload.data.logs),
      };
      state.data = formatLocation(state.data)
      return state
    case actionTypes.UPDATE_DETAIL_COLOR_FAIL:
      state.submiting = false
      return state

    case actionTypes.UPDATE_STATUS_DETAIL_COLOR_SUCCESS:
      state.submiting = false;
      state.data = {
        ...action.payload.data,
        listLog: formatLogs(action.payload.data.logs),
      };
      state.data = formatLocation(state.data)
      return state
    case actionTypes.UPDATE_STATUS_DETAIL_COLOR_FAIL:
      state.submiting = false;
      state.error = action.payload
      return state
    case "CLEAR_COLOR_REQUEST":
      state = initialState
      return state
    default:
      return state
  }
});
export default ColorRequestDetail;

const formatLogs = (logs) => {
  let temp = [];
  for (let i = 0; i < logs.list.length; i++) {
    let item = logs.list[i];
    if(item.action === "edit"){
      temp.push(item)
    }
    else{
      if (item.action === 1) {
        temp.push({
          ...item,
          classColor: "arrow-status-1",
        });
      }
      if (item.action === 3) {
        temp.push({
          ...item,
          classColor: "arrow-status-3",
        });
      }
      if (item.action === 4) {
        temp.push({
          ...item,
          classColor: "arrow-status-4",
        });
      }
      if (item.action === 2) {
        temp.push({
          ...item,
          classColor: "arrow-status-2",
        });
      }
      if (item.action === -1) {
        temp.push({
          ...item,
          classColor: "arrow-status-20",
        });
      }
      if (item.action === -3) {
        temp.push({
          ...item,
          classColor: "arrow-status-10",
        });
      }
    }
  }
  return temp
}

const formatLocation = (data) => {
  if(data.city_info){
    data.city_info = {
      value: data.city_info.id+"",
      key: data.city_info.id+"",
      label: data.city_info.name
    }
  }
  if(data.district_info){
    data.district_info = {
      value: data.district_info.id+"",
      key: data.district_info.id+"",
      label: data.district_info.name
    }
  }
  return data
}
