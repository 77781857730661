import * as actionType from "./../actions/actionTypes";
import _cloneDeep from 'lodash/cloneDeep'

const initialState = {
  isLoading: false,
  productDetail: {},
  productGroup: [],
  productGroup3D: [],
  productItem3D: [],
  productType: [],
  productProperties: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionType.GET_PRODUCT_DETAIL:
      return {
        ...state,
        isLoading: true,
      };
    case actionType.GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        productDetail: format3DiGroupId(action.payload),
      };
    case actionType.GET_PRODUCT_DETAIL_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionType.GET_PRODUCT_GROUP_SUCCESS:
      return {
        ...state,
        productGroup: action.payload,
      };
    case actionType.GET_PRODUCT_TYPE_SUCCESS:
      return {
        ...state,
        productType: action.payload,
      };
    case actionType.GET_PRODUCT_GROUP_3D_SUCCESS:
      return {
        ...state,
        productGroup3D: action.payload,
      };
    case actionType.CLEAR_PRODUCT_DETAIL:
      return {
        ...state,
        productDetail: {},
        productGroup: [],
        productGroup3D: [],
        productType: [],
      };
    case actionType.UPDATE_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        productDetail: format3DiGroupId(action.payload),
      };
    case actionType.GET_PRODUCT_ITEM_3D_SUCCESS:
      return {
        ...state,
        productItem3D: action.payload,
      };
    case actionType.GET_PRODUCT_PROPERTIES_SUCESS:
      return {
        ...state,
        productProperties: action.payload,
      };
    case actionType.ADD_LIST_PACKAGE_SUCCESS:
      return {
        ...state,
        productDetail: format3DiGroupId(action.payload),
      };
    case actionType.UPDATE_LIST_PACKAGE_SUCCESS:
      return {
        ...state,
        productDetail: format3DiGroupId(action.payload),
      };
    case actionType.DELETE_PACKAGE_PRODUCT_SUCCESS:
      return {
        ...state,
        productDetail: format3DiGroupId(action.payload),
      };
    case actionType.UPDATE_STATUS_PACKAGE_SUCCESS:
      return {
        ...state,
        productDetail: format3DiGroupId(action.payload),
      };
    default:
      return state;
  }
}

const format3DiGroupId = (payload => {
  let cloneState = _cloneDeep(payload)
  cloneState.list_group_3di_id = cloneState.list_group_3di.map(group => group.obj_id)
  return {...cloneState}
})
