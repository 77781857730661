import React, { useState, useEffect } from "react";
import { Button, Form, Select, DatePicker, InputNumber } from "antd";
import FilterFormItem from "../../../component/Filter/FilterFormItem";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";
import { renderURL, unaccent } from "../../../utils";
import { useLocation, useHistory } from "react-router-dom";
import { fetchLocation } from "../../../actions/api";
import _ from "lodash";
import { list_status, findStatus } from "../../../commons/Dealer";
import { routerChange } from "./../../../utils/urlChange";
import moment from "moment";
import * as actionTypes from "./../../../actions/actionTypes";
import { connect } from "react-redux";
const { Option } = Select;
const queryString = require("query-string");
const ColorRequestFilter = (
  props
) => {
  const location = useLocation();
  const history = useHistory();
  const [RequestFilterForm] = Form.useForm();
  const [stateFilter, setStateFilter] = useState({});
  const renderSelectOption = (data) => {
    if (data && data.length > 0) {
      return data.map((l) => {
        l.unaccent_name = unaccent(l.label);
        return (
          <Option key={l.key} info={l}>
            {l.label}
          </Option>
        );
      });
    }
    return null;
  };

  let url = history.location.search.toString();
  
  useEffect(() => {
    props.fetCities();
    return () => {
      props.clearColorRequest()
    }
  }, [])

  useEffect(() => {
    if (url) {
      let values = queryString.parse(url);
      if (values.status) {
        RequestFilterForm.setFieldsValue({
          status: parseInt(values.status),
        });
      }
      else{
        RequestFilterForm.setFieldsValue({
          status: undefined,
        });
      }
      if (values.city_id) {
        RequestFilterForm.setFieldsValue({
          city_id: parseInt(values.city_id),
        });
        props.fetDistrictsByCity(parseInt(values.city_id));
      }
      else{
        RequestFilterForm.setFieldsValue({
          city_id: undefined,
        });
        props.fetDistricts();
      }

      if (values.district_id) {
        RequestFilterForm.setFieldsValue({
          district_id: parseInt(values.district_id),
        });
      }
      else{
        RequestFilterForm.setFieldsValue({
          district_id: undefined,
        });
      }

      if (values.created_from) {
        RequestFilterForm.setFieldsValue({
          created_from: moment(values.created_from),
        });
      }
      else{
        RequestFilterForm.setFieldsValue({
          created_from: undefined,
        });
      }
      if (values.created_to) {
        RequestFilterForm.setFieldsValue({
          created_to: moment(values.created_to),
        });
      }
      else{
        RequestFilterForm.setFieldsValue({
          created_to: undefined,
        });
      }
    } else {
      props.fetDistricts();
      let values = queryString.parse(url);

      RequestFilterForm.setFieldsValue({
        status: values.status *1 || undefined,
        city_id: values.city_id *1 || undefined ,
        district_id: values.district_id *1 || undefined ,
        created_from: values.created_from ? moment(values.created_from) : undefined ,
        created_to: values.created_to ? moment(values.created_to) : undefined ,
      })
      url = "?"+ queryString.stringify(props.initFilter)
    }

    props.fetColorRequest(props.initFilter);

  }, [location.search]);

  const onChangeCity = (value) => {
    if (value) {
      props.fetDistrictsByCity(value);
      RequestFilterForm.setFieldsValue({
        district_id: undefined,
      });
    } else {
      let temp = stateFilter;
      if (temp.city_id) {
        delete temp.city_id;
        // routerChange(history, location.pathname, temp);
        setStateFilter(temp);
        props.fetColorRequest(temp);
        // props.resetCurrentPage(1);
      }
      props.fetDistricts();
      RequestFilterForm.setFieldsValue({
        district_id: undefined,
      });
    }
  };
  function disabledDate(current) {
    // Can not select days before today and today
    if (RequestFilterForm.getFieldValue("created_from")) {
      return (
        current && current < RequestFilterForm.getFieldValue("created_from")
      );
    }
  }
  function disabledDateFrom(current) {
    // Can not select days before today and today
    if (RequestFilterForm.getFieldValue("created_to")) {
      return current && current > RequestFilterForm.getFieldValue("created_to");
    }
  }
  let filterItems = [
    {
      name: "status",
      placeholder: "Trạng thái ",
      className: "mr-2 mb-2",
      render: (submitOnClear) => (
        <Select
          showSearch
          allowClear
          onChange={submitOnClear}
          style={{ width: "100%" }}
          placeholder="Trạng thái"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            unaccent(option.children)
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {status.map((s, index) => (
            <Option value={s.id} key={index}>
              {s.label}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      name: "city_id",
      placeholder: "Thành phố /Tỉnh",
      className: "mr-2 mb-2",
      render: () => (
        <Select
          showSearch
          allowClear
          style={{ width: "100%" }}
          placeholder="Thành phố /Tỉnh"
          optionFilterProp="children"
          onChange={onChangeCity}
          filterOption={(input, option) =>
            unaccent(option.children)
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0 ||
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {props.Cities.cities.map((city, index) => (
            <Option value={city.id} key={index}>
              {city.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      name: "district_id",
      placeholder: "Quận",
      className: "mr-2 mb-2",
      render: (submitOnClear) => (
        <Select
          showSearch
          allowClear
          onChange={submitOnClear}
          style={{ width: "100%" }}
          placeholder="Quận"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            unaccent(option.children)
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {props.Districts.districts.map((city, index) => (
            <Option value={city.id} key={index}>
              {city.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      name: "created_from",
      placeholder: "Ngày tạo từ",
      className: "mr-2 mb-2",
      render: (submitOnClear) => (
        <DatePicker
          disabledDate={disabledDateFrom}
          onChange={submitOnClear}
          format="DD/MM/YYYY"
          placeholder="Ngày tạo từ"
          className="w-100"
        />
      ),
    },
    {
      name: "created_to",
      placeholder: "Ngày tạo đến",
      className: "mb-2",
      render: (submitOnClear) => (
        <DatePicker
          disabledDate={disabledDate}
          onChange={submitOnClear}
          format="DD/MM/YYYY"
          placeholder="Ngày tạo đến"
          className="w-100"
        />
      ),
    },
  ];

  const onFinish = (values) => {
    let filter = Object.assign({}, values);
    filter.page = 1;
    filter.perPage = 10;
    if (!filter.status) {
      delete filter.status;
    }
    if (!filter.city_id) {
      delete filter.city_id;
    }
    if (!filter.district_id) {
      delete filter.district_id;
    }
    if (!filter.created_from) {
      delete filter.created_from;
    }
    if (!filter.created_to) {
      delete filter.created_to;
    }
    if (filter.created_from) {
      filter.created_from = moment(filter.created_from).format("YYYY-MM-DD");
    }
    if (filter.created_to) {
      filter.created_to = moment(filter.created_to).format("YYYY-MM-DD");
    }
    history.push(location.pathname + "?" + renderURL(location.pathname, filter));
    setStateFilter(filter);
    // props.fetColorRequest(history.location.search.toString());
    // props.resetCurrentPage(1);
  };

  const handleValuesChange = (value, allValues) => {};

  const setMinDate = () => {
    let { created_from } = props.filter;
    return created_from && created_from < moment().endOf("day");
  };

  const onResetFilter = () => {
    history.push(location.pathname + "?" + renderURL(location.pathname, { perPage: 10, page: 1 }));
  };
  return (
    <Form
      name="dealer_filter_form"
      form={RequestFilterForm}
      onFinish={onFinish}
      onValuesChange={handleValuesChange}
      initialValues={props.filter}
    >
      {/* <div className="d-flex align-items-center mb-3 flex-wrap"> */}
        <FilterFormItem form={RequestFilterForm} renderFilter={filterItems} />
      {/* </div> */}
      <div className="d-flex justify-content-end">
        <Button
          className="ml-2"
          type="primary"
          htmlType="submit"
          icon={<SearchOutlined />}
        >
          Tìm
        </Button>
        <Button
          className="ml-2"
          onClick={onResetFilter}
          icon={<ClearOutlined />}
        >
          Xóa
        </Button>
      </div>
    </Form>
  );
};
const status = [
  {
    id: -1,
    key: "-1",
    label: "Đã xóa",
  },
  {
    id: 1,
    key: "1",
    label: "Mới tạo",
  },
  {
    id: 2,
    key: "2",
    label: "Đợi duyệt",
  },
  {
    id: -2,
    key: "-2",
    label: "Đã hủy",
  },
  {
    id: 3,
    key: "3",
    label: "Đã duyệt",
  },
  {
    id: -3,
    key: "-3",
    label: "Đã từ chối",
  },
  {
    id: 4,
    key: "4",
    label: "Hoàn thành",
  },
];
const mapStateToProps = (state) => {
  return {
    Cities: state.Cities,
    Districts: state.Districts,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetColorRequest: (param) => {
      dispatch({
        type: actionTypes.FET_REQUEST_COLOR,
        payload: param,
      });
    },
    clearColorRequest: () => {
      dispatch({
        type: actionTypes.CLEAR_REQUEST_COLOR,
      });
    },
    fetCities: () => {
      dispatch({
        type: actionTypes.FET_CITIES,
      });
    },
    fetDistricts: () => {
      dispatch({
        type: actionTypes.FET_DISTRICTS,
      });
    },
    fetDistrictsByCity: (payload) => {
      dispatch({
        type: actionTypes.FET_DISTRICT_BY_CITY,
        payload,
      });
    },
    // fetDistrictByCity: (cityId) => {
    //   dispatch({
    //     type: actionTypes.FET_DISTRICT_BY_CITY,
    //     payload: cityId,
    //   });
    // },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ColorRequestFilter);
