import {
  FET_SURVEY,
  FET_SURVEY_SUCCESS,
  FET_SURVEY_FAIL,
  FET_SURVEY_DETAIL,
  FET_SURVEY_DETAIL_SUCCESS,
  FET_SURVEY_DETAIL_FAIL,
  UPDATE_QUESTION_SUCCESS,
  UPDATE_STATUS_SURVEY_SUCCESS,
  CREATE_SURVEY_QUESTION_SUCCESS,
  DELETE_QUESTION_SUCCESS,
} from "./../actions/actionTypes";
import { produce } from "immer";
const initialState = {
  loading: false,
  total: 0,
  data: [],
  detail: {},
};
const Surveys = produce((state = initialState, action = {}) => {
  switch (action.type) {
    case FET_SURVEY:
      state.loading = true;
      return state;
    case FET_SURVEY_SUCCESS:
      state.loading = false;
      state.total = action.payload.data.total;
      state.data = action.payload.data.data;
      return state;
    case FET_SURVEY_FAIL:
      state.loading = false;
      return state;
    case FET_SURVEY_DETAIL:
      state.loading = true;
      return state;
    case FET_SURVEY_DETAIL_SUCCESS:
      state.loading = false;
      state.detail = action.payload.data;
      return state;
    case FET_SURVEY_DETAIL_FAIL:
      state.loading = false;
      return state;
    case UPDATE_QUESTION_SUCCESS:
      //state.loading = false;
      let dataQuestions = state.detail.question_list;
      let index = dataQuestions.findIndex((dq) => dq.id === action.payload.id);
      dataQuestions[index] = action.payload;
      state.detail.question_list = dataQuestions;
      return state;
    case UPDATE_STATUS_SURVEY_SUCCESS:
      //state.loading = false;
      let tempDetail = state.detail;
      tempDetail = action.payload.data;
      state.detail = tempDetail;
      return state;
    case CREATE_SURVEY_QUESTION_SUCCESS:
      //state.loading = false;
      let tempDetailQuestion = state.detail.question_list;
      tempDetailQuestion.push(action.payload.data);
      state.detail.question_list = [...tempDetailQuestion];
      return state;
    case DELETE_QUESTION_SUCCESS:
      let tempDataQuestions = state.detail.question_list;
      let indexDelete = tempDataQuestions.findIndex(
        (tq) => tq.id === action.payload,
      );
      tempDataQuestions.splice(indexDelete, 1);
      state.detail.question_list = [...tempDataQuestions];
      return state;
    case "CLEAR_SURVEY":
      state.loading = false;
      state.total = 0;
      state.data = [];
      state.detail = {};
      return state;
    default:
      return state;
  }
});
export default Surveys;
