import axios from "axios";
import history from "../history";
import { message } from "antd";
import {
  REQUEST,
  APPROVED,
  DENIED,
  SENDING,
  STOPPED,
  COMPLETE,
  DELETED,
  DRAFT,
} from "../commons/Notify";

let errorCatch = false;

export function checkLocalStorage() {
  try {
    return JSON.parse(localStorage.auth).token;
  } catch (err) {
    localStorage.removeItem("auth");
    window.location.href = "/#/login";
  }
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_CRM_API,
});

instance.interceptors.response.use(
  function (config) {
    errorCatch = false;
    return config;
  },
  function (error) {
    if (error.response && error.response.status) {
      if (error.response.status === 401) {
        if (!errorCatch) {
          let token = checkLocalStorage();
          clearPushwoosh(token);
          localStorage.removeItem("auth");
          history.push("/login");
          message.warning("Tài khoản hết hiệu lực sử dụng");
        }
        errorCatch = true;
      }
    }
    return Promise.reject(error);
  }
);

//pushwoosh
export function registerPushwoosh(data, token) {
  let req = axios.post(
    process.env.REACT_APP_NOTI_API + "v1/notify/panel/regpw",
    data,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return req;
}
export function clearPushwoosh(token) {
  let req = axios.post(
    process.env.REACT_APP_NOTI_API + "v1/notify/panel/clearpw",
    {},
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return req;
}

export const login = function (user_name, password) {
  const req = instance.post("login", {
    user_name,
    password,
  });
  return req;
};

export const logout = function () {
  let token = checkLocalStorage();
  const req = instance.put(
    "logout",
    {},
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  req.then((res) => {
    clearPushwoosh(token);
  });
};

export const logoutApp = function (id) {
  let token = checkLocalStorage();
  const req = instance.put(
    "members-logout/" + id,
    {},
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return req;
};

export const putUserPassword = function (params) {
  let token = checkLocalStorage();
  const req = instance.put("password", params, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const putUserPasswordById = function (id, params) {
  let token = checkLocalStorage();
  const req = instance.put("users-password/" + id, params, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const putUserStatus = function (id, params) {
  let token = checkLocalStorage();
  const req = instance.put("users-status/" + id, params, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const fetchMember = function (id) {
  let token = checkLocalStorage();
  const req = instance.get("members/" + id, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const fetchMembers = function (params) {
  let token = checkLocalStorage();
  const req = instance.get("members", {
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const putMember = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("members/" + id, data, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const postMember = function (data) {
  let token = checkLocalStorage();
  const req = instance.post("members", data, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const fetchStaff = function (params) {
  let token = checkLocalStorage();
  const req = instance.get("staffs", {
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const fetchLocation = function (params) {
  let token = checkLocalStorage();
  const req = instance.get("locations", {
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const fetchDealers = function (params) {
  let token = checkLocalStorage();
  const req = instance.get("dealers", {
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};
export const fetchDealer = function (id) {
  let token = checkLocalStorage();
  const req = instance.get("dealers/" + id, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const fetchDealersV2 = function (params) {
  let token = checkLocalStorage();
  const req = instance.get("dealers-npv", {
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};
export const fetchDealerV2 = function (id) {
  let token = checkLocalStorage();
  const req = instance.get("dealers-npv/" + id, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};
export const fetchDealerLocation = function (params) {
  let token = checkLocalStorage();
  const req = instance.get("locations-npv", {
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const fetchMemberChangeLog = function (params) {
  let token = checkLocalStorage();
  const req = instance.get("members-changed-logs", {
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const fetchTransactionLog = function (params) {
  let token = checkLocalStorage();
  const req = instance.get("transaction-logs", {
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const fetchNote = function (params) {
  let token = checkLocalStorage();
  const req = instance.get("notes", {
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const fetchFile = function (params) {
  const req = axios.get(process.env.REACT_APP_FILE_API + "files", {
    params,
  });
  return req;
};

export const fetchUsers = function (params) {
  let token = checkLocalStorage();
  const req = instance.get("users", {
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const fetchUser = function (id) {
  let token = checkLocalStorage();
  const req = instance.get("users/" + id, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const putUser = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("users/" + id, data, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const postUser = function (data) {
  let token = checkLocalStorage();
  const req = instance.post("users", data, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const fetchNotifications = function (params) {
  let token = checkLocalStorage();
  const req = instance.get("notifications", {
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const postNote = function (data) {
  let token = checkLocalStorage();
  const req = instance.post("notes", data, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const postFile = function (data) {
  const req = axios.post(process.env.REACT_APP_FILE_API + "file", data, {
    headers: {
      // Authorization: 'Bearer ' + token
    },
  });
  return req;
};

export const deleteFile = function (id) {
  const req = axios.delete(process.env.REACT_APP_FILE_API + "file/" + id, {
    headers: {
      // Authorization: 'Bearer ' + token
    },
  });
  return req;
};

export const updateStatusMember = function (member_id, data) {
  let token = checkLocalStorage();
  const req = instance.put("members-status/" + member_id, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const fetchEvents = function (params) {
  let token = checkLocalStorage();
  const req = instance.get("events", {
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const postEvent = function (data) {
  let token = checkLocalStorage();
  const req = instance.post("events", data, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};
export const postEventGift = function (data) {
  let token = checkLocalStorage();
  const req = instance.post("events-gifts", data, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};
export const putEventGift = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("events-gifts/" + id, data, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};
export const deleteEventGift = function (id) {
  let token = checkLocalStorage();
  const req = instance.delete("events-gifts/" + id, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};
export const putEntryPrize = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("entries-prizes/" + id, data, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};
export const getEventGift = function (filter) {
  let token = checkLocalStorage();
  const req = instance.get("events-gifts", {
    params: filter,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};
export const fetchEvent = function (id) {
  let token = checkLocalStorage();
  const req = instance.get("events/" + id, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};
export const putEventStatus = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("events-status/" + id, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const putEvent = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("events/" + id, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const fetchEntries = function (params, responseType = undefined) {
  let token = checkLocalStorage();
  const req = instance.get("entries", {
    responseType,
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const fetchEntry = function (id) {
  let token = checkLocalStorage();
  const req = instance.get("entries/" + id, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const putEntryStatus = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("entries-status/" + id, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const putEntry = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("entries/" + id, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const postEntryProduct = function (data) {
  let token = checkLocalStorage();
  const req = instance.post("entries-products", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const putEntryProduct = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("entries-products/" + id, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const deleteEntryProduct = function (id) {
  let token = checkLocalStorage();
  const req = instance.delete("entries-products/" + id, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const fetchWarranties = function (params, responseType = undefined) {
  let token = checkLocalStorage();
  const req = instance.get("warranty-requests", {
    responseType,
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const fetchWarranty = function (id) {
  let token = checkLocalStorage();
  const req = instance.get("warranty-requests/" + id, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const putWarranty = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("warranty-requests/" + id, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const putWarrantyStatus = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("warranty-requests-status/" + id, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const putWarrantyRequest = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("warranty-requests-products/" + id, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
//color request
export const fetColorRequestFromApi = function (
  params,
  responseType = undefined
) {
  let token = checkLocalStorage();
  const req = instance.get("support-requests", {
    params,
    responseType,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const fetLocationFromApi = function () {
  let token = checkLocalStorage();
  const req = instance.get("locations?get_full=true&type=1", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const fetDistrictFromApi = function () {
  let token = checkLocalStorage();
  const req = instance.get("locations?type=2&get_full=true", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const fetDistrictByCityFromApi = function (idCity) {
  let token = checkLocalStorage();
  const req = instance.get("locations?get_full=true&parent_id=" + idCity, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const fetColorRequestDetailFromApi = function (id) {
  let token = checkLocalStorage();
  const req = instance.get("support-requests/" + id, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const updateStatusDetailFromApi = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("support-requests-status/" + id, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const putColorRequest = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("support-requests/" + id, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const getProductDetail = function (id) {
  let token = checkLocalStorage();
  const req = instance.get(`products/${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const fetchArticleCategory = function (params) {
  let token = checkLocalStorage();
  const req = instance.get("news-category", {
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};
export const fetchArticles = function (params) {
  let token = checkLocalStorage();
  const req = instance.get("news", {
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};
export const fetchArticle = function (id) {
  let token = checkLocalStorage();
  const req = instance.get("news/" + id, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const postArticle = function (data) {
  let token = checkLocalStorage();
  const req = instance.post("news/", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const putArticle = function (data, id) {
  let token = checkLocalStorage();
  const req = instance.put("news/" + id, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const putArticleStatus = function (data, id) {
  let token = checkLocalStorage();
  const req = instance.put("news-status/" + id, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const getProductGroup = function () {
  let token = checkLocalStorage();
  const req = instance.get("/product-groups?get_full=true", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const getProductType = function () {
  let token = checkLocalStorage();
  const req = instance.get("/product-types", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
//van dai product list
export const getProductFromApi = function (params) {
  let token = checkLocalStorage();
  const req = instance.get("products", {
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};
export const getProductGroupFromApi = function () {
  let token = checkLocalStorage();
  const req = instance.get("product-groups?get_full=true", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const getProductGroup3D = function (type, parent_ids) {
  let token = checkLocalStorage();
  const req = instance.get(
    parent_ids
      ? `/product-groups-3di?type=${type}&get_full=true&parent_ids=${JSON.stringify(parent_ids)}`
      : `/product-groups-3di?type=${type}&get_full=true`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return req;
};

export const getProductGroup3DNotUse = function (type) {
  let token = checkLocalStorage();
  const req = instance.get(
    `/product-groups-3di?type=${type}&get_full=true&check_relation=-1`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  return req;
};

export const addProduct = function (data) {
  let token = checkLocalStorage();
  const req = instance.post("/products", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const getProductTypeFromApi = function () {
  let token = checkLocalStorage();
  const req = instance.get("product-types", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const updateProduct = function (data, idProduct) {
  let token = checkLocalStorage();
  const req = instance.put(`/products/${idProduct}`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
//end van dai

export const getProductProperties = (type) => {
  let token = checkLocalStorage();
  const req = instance.get(`/product-properties?type=${type}&get_full=true`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const addPackageList = function (data) {
  let token = checkLocalStorage();
  const req = instance.post("/products-detail", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const updatePackageList = function (data, product_id) {
  let token = checkLocalStorage();
  const req = instance.put(`/products-detail/${product_id}`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const deletePackageProduct = function (package_id) {
  let token = checkLocalStorage();
  const req = instance.delete(`/products-detail/${package_id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const updateStatusPackage = function (package_id, status) {
  let token = checkLocalStorage();
  const req = instance.put(`/products-detail/${package_id}`, status, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const fetchBanners = function (params) {
  let token = checkLocalStorage();
  const req = instance.get("app-banners", {
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const putBanner = function (data, id) {
  let token = checkLocalStorage();
  const req = instance.put("app-banners/" + id, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const postBanner = function (data) {
  let token = checkLocalStorage();
  const req = instance.post("app-banners", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const putBannerStatus = function (data, id) {
  let token = checkLocalStorage();
  const req = instance.put("app-banners-status/" + id, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const getTotalNews = () => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-news`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const getTotalDealer = () => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-dealers`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const getTotalMember = () => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-members`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const getTop5Interaction = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-members-top5-interraction`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const getOnlineTime = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-members-top5-online-time`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const getTotalViewNews = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-views-total`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const requestBH = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-warranty-requests`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const requestPM = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-support-requests`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const getNewKTD = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-entries`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const top3News = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-news-top3-view`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const getAppModuleInteractive = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-module-interative`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const getDashboarStatistic = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-global`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const fetchDashboardScanning = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-check-codes`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const fetchEventTotalEntry = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-total-events`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const fetchAppModuleUsageTime = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-module-usage-time`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const fetchTop5SearchProduct = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-search-product-top5`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const getNews = () => {
  let token = checkLocalStorage();
  const req = instance.get(`/events`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

// group user permision
export const fetchGroupUsers = function (params) {
  let token = checkLocalStorage();
  const req = instance.get("rbac-roles", {
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};
export const fetchGroupUserDetail = function (id) {
  let token = checkLocalStorage();
  const req = instance.get("rbac-roles/" + id, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};
export const fetchGroupUsersModule = function (params) {
  let token = checkLocalStorage();
  const req = instance.get("rbac-modules", {
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};
export const updatePermissionByGroupUsers = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put(`/rbac-roles/${id}`, data, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const fetchLuckyDrawTrans = function (params) {
  let token = checkLocalStorage();
  const req = instance.get("lucky-draw-transactions/", {
    params,
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};
export const fetchLuckyDrawTransByID = function (id) {
  let token = checkLocalStorage();
  const req = instance.get("lucky-draw-transactions/" + id, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};
export const putLuckyDrawTransStatus = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("lucky-draw-transactions/" + id, data, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};
export const putLuckyDrawTrans = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("lucky-draw-transactions/" + id, data, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const getTotalPoint = () => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-total-point`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const getPoint = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-point`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const getPlayQuantity = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-game-turn`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const top3ProductScan = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-scan-code-top-3-product`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const getPlayTime = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-gametime-top5`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const getTop5GamerOnline = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/statistic-gametime-top5`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const fetchMemberScanLogs = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/scan-logs`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const fetchUserRoles = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/user-roles`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const putUserRoles = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("user-roles/" + id, data, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const putNPVDealerType = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("dealers-npv/" + id, data, {
    headers: { Authorization: "Bearer " + token },
  });
  return req;
};

export const fetchLuckyDrawEvents = (params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/lucky-draw-events`, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const fetchLuckyDrawEvent = (id) => {
  let token = checkLocalStorage();
  const req = instance.get(`/lucky-draw-events/` + id, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const fetchMiniGame = (id, params) => {
  let token = checkLocalStorage();
  const req = instance.get(`/games/` + id, {
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const fetchNotify = (params) => {
  let token = checkLocalStorage();
  const req = instance.get("notify/all", {
    baseURL: process.env.REACT_APP_NOTI_API,
    params,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const fetchNotifyDetail = (id) => {
  let token = checkLocalStorage();
  const req = instance.get("notify/detail/" + id, {
    baseURL: process.env.REACT_APP_NOTI_API,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const postNotify = (data) => {
  let token = checkLocalStorage();
  const req = instance.post("notify/draft", data, {
    baseURL: process.env.REACT_APP_NOTI_API,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const putNotifyStatus = (status, data) => {
  let token = checkLocalStorage();
  let url = "";
  if (status === REQUEST.id) url = "request";
  if (status === APPROVED.id) url = "approve";
  if (status === STOPPED.id) url = "stop";
  if (status === DENIED.id) url = "deny";
  if (status === DRAFT.id) url = "backtodraft";
  if (url) {
    const req = instance.put("notify/" + url, data, {
      baseURL: process.env.REACT_APP_NOTI_API,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return req;
  }
  message.error("Cập nhật trạng thái thất bại!");
};
export const putNotify = (data) => {
  let token = checkLocalStorage();
  const req = instance.put("notify/save", data, {
    baseURL: process.env.REACT_APP_NOTI_API,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export const deleteNotify = (id) => {
  let token = checkLocalStorage();
  const req = instance.delete("notify/delete/" + id, {
    baseURL: process.env.REACT_APP_NOTI_API,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
//survey by Van dai
export const fetSurveyFromApi = function (filter) {
  let token = checkLocalStorage();
  const req = instance.get("surveys", {
    params: filter,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const fetSurveyDetail = function (id) {
  let token = checkLocalStorage();
  const req = instance.get(`surveys/${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const postSurvey = function (data) {
  let token = checkLocalStorage();
  const req = instance.post("surveys", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
// winner entries by Q.Thi
export const fetWinnerEntries = function (filter, responseType = undefined) {
  let token = checkLocalStorage();
  const req = instance.get("entries-prizes", {
    responseType,
    params: filter,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const putSurvey = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("surveys/" + id, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const updateQuestion = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("survey_questions/" + id, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const deleteQuestion = function (id) {
  let token = checkLocalStorage();
  const req = instance.delete("survey_questions/" + id, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const updateStatusSurvey = function (id, data) {
  let token = checkLocalStorage();
  const req = instance.put("surveys-status/" + id, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

export function fetchServiceNoti(params) {
  let token = checkLocalStorage();
  let req = axios.get(
    process.env.REACT_APP_NOTI_PUSHWOOSH + "v1/notify/panel/list",
    {
      params,
      headers: { Authorization: "Bearer " + token },
    }
  );
  return req;
}
export function fetchUnreadNoti(params) {
  let token = checkLocalStorage();
  let req = axios.get(
    process.env.REACT_APP_NOTI_PUSHWOOSH +
      "v1/notify/panel/totalUnreadMultiCat",
    {
      params,
      headers: { Authorization: "Bearer " + token },
    }
  );
  return req;
}
export function fetchServiceNotiById(id) {
  let token = checkLocalStorage();
  let req = axios.get(
    process.env.REACT_APP_NOTI_PUSHWOOSH + "v1/notify/panel/detail/" + id,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return req;
}
// export function fetchServiceNotiByLongId(id) {
//   let token = checkLocalStorage();
//   let req = axios.get(process.env.REACT_APP_NOTI_PUSHWOOSH + "v1/notify/panel/detail/" + id, {
//     headers: { Authorization: 'Bearer ' + token }
//   })
//   return req;
// }

export function putNotiToSeen(query) {
  let token = checkLocalStorage();
  const req = axios.put(
    process.env.REACT_APP_NOTI_PUSHWOOSH + "v1/notify/panel/read",
    query,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return req;
}

export function postAllNotiToSeen(query) {
  let token = checkLocalStorage();
  const req = axios.post(
    process.env.REACT_APP_NOTI_PUSHWOOSH + "v1/notify/panel/readAll",
    query,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  return req;
}
export const postSurveyQuestions = function (data) {
  let token = checkLocalStorage();
  const req = instance.post("survey_questions", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
//list gift lucky draw
export const fetchLuckyDrawGifts = () => {
  let token = checkLocalStorage();
  const req = instance.get(`lucky-draw-gift`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};

//get App version
export const fetchAppVersions = (flatform) => {
  let token = checkLocalStorage();
  const req = instance.get(`app-versions/${flatform}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
export const putAppVersions = (flatform, data) => {
  let token = checkLocalStorage();
  const req = instance.put(`app-versions/${flatform}`, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return req;
};
