import * as actions from "./api";
import {
  call,
  put,
  select,
  take,
  fork,
  actionChannel,
} from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import swal from "sweetalert2";
import { message } from "antd";

export function* fetchMember(action) {
  try {
    const req = yield call(actions.fetchMember, action.payload);
    yield put({ type: "FETCH_MEMBER_SUCCESS", payload: req });
  } catch (error) {
    // if (checkOnlineAndToken(error)) {
    yield put({ type: "FETCH_MEMBER_FAILED", payload: error });
    // }
  }
}

export function* fetchCities(action) {
  try {
    const req = yield call(actions.fetchLocation, action.payload);
    yield put({ type: "FETCH_CITIES_SUCCESS", payload: req.data });
  } catch (error) {
    // if (checkOnlineAndToken(error)) {
    yield put({ type: "FETCH_CITIES_FAILED", payload: error });
    // }
  }
}

export function* fetchUsers(action) {
  try {
    const req = yield call(actions.fetchUsers, action.payload);
    yield put({ type: "FETCH_USERS_SUCCESS", payload: req && req.data });
  } catch (error) {
    // if (checkOnlineAndToken(error)) {
    yield put({ type: "FETCH_USERS_FAILED", payload: error });
    // }
  }
}

export function* fetchGroupUsers(action) {
  try {
    const req = yield call(actions.fetchGroupUsers, action.payload);
    yield put({ type: "FETCH_GROUP_USERS_SUCCESS", payload: req && req.data });
  } catch (error) {
    // if (checkOnlineAndToken(error)) {
    yield put({ type: "FETCH_GROUP_USERS_FAILED", payload: error });
    // }
  }
}

export function* getGroupUserDetailSaga({ payload }) {
  try {
    const { data } = yield call(actions.fetchGroupUserDetail, payload);
    yield put({
      type: actionTypes.GET_GROUP_USER_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: actionTypes.GET_GROUP_USER_DETAIL_FAIL });
  }
}

export function* fetchNotifications(action) {
  try {
    const req = yield call(actions.fetchNotifications, action.payload);
    yield put({
      type: "FETCH_NOTIFICATIONS_SUCCESS",
      payload: req && req.data,
    });
  } catch (error) {
    // if (checkOnlineAndToken(error)) {
    yield put({ type: "FETCH_NOTIFICATIONS_FAILED", payload: error });
    // }
  }
}
export function* fetColorRequest(action) {
  try {
    const req = yield call(actions.fetColorRequestFromApi, action.payload);
    yield put({
      type: actionTypes.FET_REQUEST_COLOR_SUCCESS,
      payload: req,
    });
  } catch (error) {
    yield put({ type: actionTypes.FET_REQUEST_COLOR_FAIL, payload: error });
  }
}
export function* fetCities() {
  try {
    const req = yield call(actions.fetLocationFromApi);
    yield put({
      type: actionTypes.FET_CITIES_SUCCESS,
      payload: req,
    });
  } catch (error) {
    yield put({ type: actionTypes.FET_CITIES_FAIL, payload: error });
  }
}
export function* fetDistrict() {
  try {
    const req = yield call(actions.fetDistrictFromApi);
    yield put({
      type: actionTypes.FET_DISTRICTS_SUCCESS,
      payload: req,
    });
  } catch (error) {
    yield put({ type: actionTypes.FET_DISTRICTS_FAIL, payload: error });
  }
}
export function* fetDistrictByCityId(action) {
  try {
    const req = yield call(actions.fetDistrictByCityFromApi, action.payload);
    yield put({
      type: actionTypes.FET_DISTRICT_BY_CITY_SUCCESS,
      payload: {
        data: req,
      },
    });
  } catch (error) {
    yield put({ type: actionTypes.FET_DISTRICT_BY_CITY_FAIL, payload: error });
  }
}
export function* fetColorRequestDetail(action) {
  try {
    const req = yield call(
      actions.fetColorRequestDetailFromApi,
      action.payload,
    );
    yield put({
      type: actionTypes.FET_COLOR_REQUEST_DETAIL_SUCCESS,
      payload: req,
    });
  } catch (error) {
    yield put({
      type: actionTypes.FET_COLOR_REQUEST_DETAIL_FAIL,
      payload: error,
    });
  }
}

export function* updateStatusColorRequest(action) {
  try {
    const req = yield call(
      actions.updateStatusDetailFromApi,
      action.payload.id,
      action.payload.data,
    );

    yield put({
      type: actionTypes.UPDATE_STATUS_DETAIL_COLOR_SUCCESS,
      payload: req,
    });
    message.success("Cập nhật trạng thái thành công");
    // swal.fire({
    //   position: "top-end",
    //   icon: "success",
    //   title: "Cập nhật trạng thái thành công",
    //   showConfirmButton: false,
    //   timer: 1500,
    // });
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_STATUS_DETAIL_COLOR_FAIL,
      payload: error,
    });
    message.error("Cập nhật trạng thái thất bại");
    // swal.fire({
    //   position: "top-end",
    //   icon: "error",
    //   title: "Cập nhật trạng thái thất bại",
    //   showConfirmButton: false,
    //   timer: 1500,
    // });
  }
}

export function* putColorRequest(action) {
  try {
    const req = yield call(
      actions.putColorRequest,
      action.payload.id,
      action.payload.data,
    );

    yield put({
      type: actionTypes.UPDATE_DETAIL_COLOR_SUCCESS,
      payload: req,
    });
    message.success("Cập nhật thành công");
    // swal.fire({
    //   position: "top-end",
    //   icon: "success",
    //   title: "Cập nhật thành công",
    //   showConfirmButton: false,
    //   timer: 1500,
    // });
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_DETAIL_COLOR_FAIL,
      payload: error,
    });
    message.error("Cập nhật thất bại");
    // swal.fire({
    //   position: "top-end",
    //   icon: "error",
    //   title: "Cập nhật thất bại",
    //   showConfirmButton: false,
    //   timer: 1500,
    // });
  }
}

//van dai product list
export function* fetProducts(action) {
  try {
    const req = yield call(actions.getProductFromApi, action.params);
    yield put({
      type: actionTypes.FET_PRODUCTS_SUCCESS,
      payload: req,
    });
  } catch (error) {
    yield put({ type: actionTypes.FET_PRODUCTS_FAIL, payload: error });
  }
}
export function* fetProductGroup() {
  try {
    const req = yield call(actions.getProductGroupFromApi);
    yield put({
      type: actionTypes.FET_PRODUCT_GROUP_SUCCESS,
      payload: req,
    });
  } catch (error) {
    yield put({ type: actionTypes.FET_PRODUCT_GROUP_FAIL, payload: error });
  }
}
export function* fetProductType() {
  try {
    const req = yield call(actions.getProductTypeFromApi);
    yield put({
      type: actionTypes.FET_PRODUCT_TYPE_SUCCESS,
      payload: req,
    });
  } catch (error) {
    yield put({ type: actionTypes.FET_PRODUCT_TYPE_FAIL, payload: error });
  }
}
//end van dai

export function* getProductDetailSaga({ payload }) {
  try {
    const { data } = yield call(actions.getProductDetail, payload);
    yield put({ type: actionTypes.GET_PRODUCT_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.GET_PRODUCT_DETAIL_FAIL });
  }
}

export function* getGroupProduct() {
  try {
    const { data } = yield call(actions.getProductGroup);
    yield put({ type: actionTypes.GET_PRODUCT_GROUP_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actionTypes.GET_PRODUCT_GROUP_FAIL });
  }
}
export function* getProductTypeSaga() {
  try {
    const { data } = yield call(actions.getProductType);
    yield put({
      type: actionTypes.GET_PRODUCT_TYPE_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({ type: actionTypes.GET_PRODUCT_TYPE_FAIL });
  }
}
export function* getProductGroup3D({ payload, parentId }) {
  try {
    if (!parentId) {
      const { data } = yield call(actions.getProductGroup3D, payload);
      yield put({
        type: actionTypes.GET_PRODUCT_GROUP_3D_SUCCESS,
        payload: data.data,
      });
    } else {
      const { data } = yield call(actions.getProductGroup3D, payload, parentId);
      yield put({
        type: actionTypes.GET_PRODUCT_ITEM_3D_SUCCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_PRODUCT_GROUP_3D_FAIL });
  }
}
export function* addProductSaga({ data, history }) {
  try {
    const result = yield call(actions.addProduct, data);
    yield put({ type: actionTypes.ADD_PRODUCT_SUCCESS, payload: result.data });
    swal.fire({
      position: "top-end",
      icon: "success",
      title: "Tạo thành công",
      showConfirmButton: false,
      timer: 1500,
    });
    history.push("/products");
  } catch (error) {
    yield put({ type: actionTypes.ADD_PRODUCT_FAIL });
  }
}
export function* updateProductDetail({ idProduct, fieldUpdate }) {
  try {
    const { data } = yield call(actions.updateProduct, fieldUpdate, idProduct);
    yield put({
      type: actionTypes.UPDATE_PRODUCT_DETAIL_SUCCESS,
      payload: data,
      loadingType: "loading_status",
    });

    yield put({ type: actionTypes.HIDE_MODAL, typeVisible: "product_status" });
    yield put({
      type: actionTypes.HIDE_EDIT_BUTTON,
      editType: "edit_button_detail",
    });

    message.success("Cập nhật thành công", 4);
  } catch (error) {
    message.error("Cập nhật thất bại", 4);
    yield put({
      type: actionTypes.UPDATE_PRODUCT_DETAIL_FAIL,
      loadingType: "loading_status",
    });
  }
}
export function* getProductProperties({ payload }) {
  try {
    const { data } = yield call(actions.getProductProperties, payload);
    if (payload === 1) {
      yield put({
        type: actionTypes.GET_PRODUCT_PROPERTIES_SUCESS,
        payload: data.data,
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.GET_PRODUCT_PROPERTIES_FAIL });
  }
}
export function* addPackageList({ payload }) {
  try {
    const productDetail = yield select((_) => _.ProductReducer.productDetail);
    const packageProduct = productDetail.product_details;
    for (let i = 0; i < packageProduct.length; i++) {
      const element = packageProduct[i];
      if (
        element?.item_group_info?.id === payload.item_group_id &&
        element?.package_info?.id === payload.package_id
      ) {
        yield put({
          type: actionTypes.ADD_LIST_PACKAGE_FAIL,
          loadingType: "loading_add_package",
        });
        return message.error(
          `${element.item_group_info.value.vi} ${element.package_info.value.vi} đã tồn tại`,
          3,
        );
      }
    }

    const { data } = yield call(actions.addPackageList, payload);
    yield put({
      type: actionTypes.ADD_LIST_PACKAGE_SUCCESS,
      payload: data,
      loadingType: "loading_add_package",
    });
    message.success("Tạo thành công", 2);
    yield put({ type: actionTypes.HIDE_MODAL, typeVisible: "package_product" });
  } catch (error) {
    message.error("Tạo thất bại", 2);
    yield put({
      type: actionTypes.ADD_LIST_PACKAGE_FAIL,
      loadingType: "loading_add_package",
    });
  }
}
export function* updatePackageList({ payload, product_id }) {
  try {
    const productDetail = yield select((_) => _.ProductReducer.productDetail);
    const packageProduct = productDetail.product_details;
    for (let i = 0; i < packageProduct.length; i++) {
      const element = packageProduct[i];
      if (
        element?.item_group_info?.id === payload.item_group_id &&
        element?.package_info?.id === payload.package_id
      ) {
        yield put({
          type: actionTypes.UPDATE_LIST_PACKAGE_FAIL,
          loadingType: "loading_add_package",
        });
        return message.error(
          `${element.item_group_info.value.vi} ${element.package_info.value.vi} đã tồn tại`,
          3,
        );
      }
    }

    const { data } = yield call(actions.updatePackageList, payload, product_id);
    yield put({
      type: actionTypes.UPDATE_LIST_PACKAGE_SUCCESS,
      payload: data,
      loadingType: "loading_add_package",
    });
    message.success("Cập nhật thành công", 2);
    yield put({ type: actionTypes.HIDE_MODAL, typeVisible: "package_product" });
  } catch (error) {
    message.error("Cập nhật thất bại", 2);
    yield put({
      type: actionTypes.UPDATE_LIST_PACKAGE_FAIL,
      loadingType: "loading_add_package",
    });
  }
}
export function* deletePackageProduct({ package_id }) {
  try {
    const { data } = yield call(actions.deletePackageProduct, package_id);
    yield put({
      type: actionTypes.DELETE_PACKAGE_PRODUCT_SUCCESS,
      payload: data,
      loadingType: package_id,
    });
    message.success("Xóa thành công", 3);
    yield put({ type: actionTypes.HIDE_MODAL, typeVisible: "package_product" });
  } catch (error) {
    yield put({
      type: actionTypes.DELETE_PACKAGE_PRODUCT_FAIL,
      loadingType: package_id,
    });
  }
}
export function* updateStatusPackage({ package_id, status }) {
  try {
    const { data } = yield call(
      actions.updateStatusPackage,
      package_id,
      status,
    );
    yield put({
      type: actionTypes.UPDATE_STATUS_PACKAGE_SUCCESS,
      payload: data,
      loadingType: `${package_id}_update_status`,
    });
    message.success("Cập nhật trạng thái thành công", 3);
    yield put({ type: actionTypes.HIDE_MODAL, typeVisible: "package_product" });
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_STATUS_PACKAGE_FAIL,
      loadingType: `${package_id}_update_status`,
    });
  }
}
export function* fetchArticle(action) {
  try {
    const { data } = yield call(actions.fetchArticle, action.payload);
    yield put({
      type: "FETCH_ARTICLE_SUCCESS",
      payload: data,
    });
  } catch (error) {
    yield put({ type: "FETCH_ARTICLE_FAILED", payload: error });
  }
}
export function* putArticle(action) {
  try {
    const { data, id, typeName } = action.payload;
    const req = yield call(actions.putArticle, data, id);
    yield put({
      type: "PUT_ARTICLE_SUCCESS",
      payload: req.data,
    });
  } catch (error) {
    yield put({ type: "PUT_ARTICLE_FAILED", payload: error });
  }
}
export function* putArticleStatus(action) {
  try {
    const { status, id } = action.payload;
    const req = yield call(actions.putArticleStatus, { status }, id);
    yield put({
      type: "PUT_ARTICLE_STATUS_SUCCESS",
      payload: req.data,
    });
  } catch (error) {
    yield put({ type: "PUT_ARTICLE_STATUS_FAILED", payload: error });
  }
}

export function* getTotalNews() {
  try {
    const { data } = yield call(actions.getTotalNews);
    yield put({
      type: actionTypes.GET_TOTAL_NEWS_SUCCESS,
      payload: data.total,
      loadingType: "getTotalNews",
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_TOTAL_NEWS_FAIL,
      loadingType: "getTotalNews",
    });
  }
}
export function* getTotalDealer() {
  try {
    const { data } = yield call(actions.getTotalDealer);
    yield put({
      type: actionTypes.GET_TOTAL_DEALER_SUCCESS,
      payload: data.total,
      loadingType: "getTotalDealer",
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_TOTAL_DEALER_FAIL,
      loadingType: "getTotalDealer",
    });
  }
}
export function* getTotalMember() {
  try {
    const { data } = yield call(actions.getTotalMember);
    yield put({
      type: actionTypes.GET_TOTAL_MEMBER_SUCCESS,
      payload: data,
      loadingType: "getTotalMember",
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_TOTAL_MEMBER_FAIL,
      loadingType: "getTotalMember",
    });
  }
}
export function* getTop5Interaction({ params }) {
  try {
    const { data } = yield call(actions.getTop5Interaction, params);
    yield put({
      type: actionTypes.TOP5_INTERACTION_SUCCESS,
      payload: data,
      loadingType: "top5Interaction",
    });
  } catch (error) {
    yield put({
      type: actionTypes.TOP5_INTERACTION_FAIL,
      loadingType: "top5Interaction",
    });
  }
}
export function* getAppModuleInteractive(action) {
  try {
    const { data } = yield call(
      actions.fetchAppModuleUsageTime,
      action.payload,
    );
    yield put({
      type: actionTypes.GET_APP_MODULE_INTERACTIVE_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_APP_MODULE_INTERACTIVE_FAIL,
      payload: error,
    });
  }
}
export function* getDashboarStatistic({ params }) {
  try {
    const { data } = yield call(actions.getDashboarStatistic, params);
    yield put({
      type: actionTypes.GET_DASHBOARD_STATISTIC_SUCCESS,
      payload: data,
      loadingType: "dashboardStatistic",
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_DASHBOARD_STATISTIC_FAIL,
      loadingType: "dashboardStatistic",
    });
  }
}
export function* getTop5GamerOnline({ params }) {
  try {
    const { data } = yield call(actions.getTop5GamerOnline, params);
    yield put({
      type: actionTypes.TOP5_GAMER_ONLINE_SUCCESS,
      payload: data,
      loadingType: "getTop5GamerOnline",
    });
  } catch (error) {
    yield put({
      type: actionTypes.TOP5_GAMER_ONLINE_FAIL,
      loadingType: "getTop5GamerOnline",
    });
  }
}
export function* getOnlineTime({ params }) {
  try {
    const { data } = yield call(actions.getOnlineTime, params);
    yield put({
      type: actionTypes.ONLINE_TIME_SUCCESS,
      payload: data,
      loadingType: "getOnlineTime",
    });
  } catch (error) {
    yield put({
      type: actionTypes.ONLINE_TIME_FAIL,
      loadingType: "getOnlineTime",
    });
  }
}
export function* getTotalViewNews({ params }) {
  try {
    const { data } = yield call(actions.getTotalViewNews, params);
    yield put({
      type: actionTypes.TOTAL_VIEW_NEWS_SUCCESS,
      payload: data,
      loadingType: "totalViewNews",
    });
  } catch (error) {
    yield put({
      type: actionTypes.TOTAL_VIEW_NEWS_FAIL,
      loadingType: "totalViewNews",
    });
  }
}
export function* getTotalRequestBH({ params }) {
  try {
    const { data } = yield call(actions.requestBH, params);
    yield put({
      type: actionTypes.REQUEST_BH_SUCCESS,
      payload: data,
      loadingType: "requestBH",
    });
  } catch (error) {
    yield put({
      type: actionTypes.REQUEST_BH_FAIL,
      loadingType: "requestBH",
    });
  }
}
export function* getTotalRequestPM({ params }) {
  try {
    const { data } = yield call(actions.requestPM, params);
    yield put({
      type: actionTypes.REQUEST_PH_SUCCESS,
      payload: data,
      loadingType: "requestPM",
    });
  } catch (error) {
    yield put({
      type: actionTypes.REQUEST_PH_FAIL,
      loadingType: "requestPM",
    });
  }
}
export function* getNewsKTD({ params }) {
  try {
    const { data } = yield call(actions.getNewKTD, params);
    yield put({
      type: actionTypes.KTD_SUCCESS,
      payload: data,
      loadingType: "getNewsKTD",
    });
  } catch (error) {
    yield put({
      type: actionTypes.KTD_FAIL,
      loadingType: "getNewsKTD",
    });
  }
}
export function* getTop3News({ params }) {
  try {
    const { data } = yield call(actions.top3News, params);
    yield put({
      type: actionTypes.TOP3_NEWS_SUCCESS,
      payload: data,
      loadingType: "getTop3News",
    });
  } catch (error) {
    yield put({
      type: actionTypes.TOP3_NEWS_FAIL,
      loadingType: "getTop3News",
    });
  }
}
export function* getNews() {
  try {
    const { data } = yield call(actions.getNews);
    yield put({
      type: actionTypes.GET_NEWS_SUCCESS,
      payload: data,
      loadingType: "getNews",
    });
    if (data.data.length > 0) {
      yield fork(loadNewsKTD);
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_NEWS_FAIL,
      loadingType: "getNews",
    });
  }
}
export function* loadNewsKTD() {
  try {
    const { data } = yield call(actions.getNewKTD);
    yield put({
      type: actionTypes.KTD_SUCCESS,
      payload: data,
      loadingType: "getNewsKTD",
    });
  } catch (error) {
    yield put({
      type: actionTypes.KTD_FAIL,
      loadingType: "getNewsKTD",
    });
  }
}

export function* fetchLuckyDrawTrans(action) {
  try {
    const { data } = yield call(actions.fetchLuckyDrawTrans, action.payload);
    yield put({
      type: actionTypes.FETCH_LUCKY_DRAW_TRANSACTIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_LUCKY_DRAW_TRANSACTIONS_FAIL,
      payload: error,
    });
  }
}
export function* fetchLuckyDrawTransDetail(action) {
  try {
    const { data } = yield call(
      actions.fetchLuckyDrawTransByID,
      action.payload,
    );
    yield put({
      type: actionTypes.FETCH_LUCKY_DRAW_TRANSACTIONS_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_LUCKY_DRAW_TRANSACTIONS_DETAIL_FAIL,
      payload: error,
    });
  }
}
export function* putLuckyDrawTransStatus(action) {
  try {
    const { data } = yield call(
      actions.putLuckyDrawTransStatus,
      action.payload.id,
      action.payload.data,
    );
    yield put({
      type: actionTypes.PUT_LUCKY_DRAW_TRANSACTIONS_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.PUT_LUCKY_DRAW_TRANSACTIONS_STATUS_FAIL,
    });
  }
}
export function* putLuckyDrawTrans(action) {
  try {
    const { data } = yield call(
      actions.putLuckyDrawTrans,
      action.payload.id,
      action.payload.data,
    );
    yield put({
      type: actionTypes.PUT_LUCKY_DRAW_TRANSACTIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.PUT_LUCKY_DRAW_TRANSACTIONS_FAIL,
    });
  }
}

export function* loadTotalPoint() {
  try {
    const { data } = yield call(actions.getTotalPoint);
    yield put({
      type: actionTypes.GET_TOTAL_POINT_SUCCESS,
      payload: data,
      loadingType: "getTotalPoint",
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_TOTAL_POINT_FAIL,
      loadingType: "getTotalPoint",
    });
  }
}

export function* loadPoint({ params }) {
  try {
    const { data } = yield call(actions.getPoint, params);
    yield put({
      type: actionTypes.GET_POINT_SUCCESS,
      payload: data,
      loadingType: "getPoint",
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_POINT_FAIL,
      loadingType: "getPoint",
    });
  }
}
export function* loadPlayQuantity({ params }) {
  try {
    const { data } = yield call(actions.getPlayQuantity, params);
    yield put({
      type: actionTypes.GET_PLAY_QUANTITY_SUCCESS,
      payload: data,
      loadingType: "getPlayQuantity",
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_PLAY_QUANTITY_FAIL,
      loadingType: "getPlayQuantity",
    });
  }
}
export function* loadTop3ProductScan({ params }) {
  try {
    const { data } = yield call(actions.top3ProductScan, params);
    yield put({
      type: actionTypes.TOP3_PRODUCT_SCAN_SUCCESS,
      payload: data,
      loadingType: "getTop3ProductScan",
    });
  } catch (error) {
    yield put({
      type: actionTypes.TOP3_PRODUCT_SCAN_FAIL,
      loadingType: "getTop3ProductScan",
    });
  }
}
export function* getPlayTime({ params }) {
  try {
    const { data } = yield call(actions.getPlayTime, params);
    yield put({
      type: actionTypes.GET_PLAY_TIME_SUCCESS,
      payload: data,
      loadingType: "getPlayTime",
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_PLAY_TIME_FAIL,
      loadingType: "getPlayTime",
    });
  }
}

export function* fetchLuckyDrawEvents(action) {
  try {
    const { data } = yield call(actions.fetchLuckyDrawEvents, action.payload);
    yield put({
      type: actionTypes.FETCH_LUCKY_DRAW_EVENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_LUCKY_DRAW_EVENTS_FAIL,
      payload: error,
    });
  }
}
export function* fetchLuckyDrawEventDetail(action) {
  try {
    const { data } = yield call(actions.fetchLuckyDrawEvent, action.payload);
    yield put({
      type: actionTypes.FETCH_LUCKY_DRAW_EVENTS_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_LUCKY_DRAW_EVENTS_DETAIL_FAIL,
      payload: error,
    });
  }
}
//survey by van dai
export function* fetchSurveys(action) {
  try {
    const req = yield call(actions.fetSurveyFromApi, action.payload);
    yield put({
      type: actionTypes.FET_SURVEY_SUCCESS,
      payload: req,
    });
  } catch (error) {
    yield put({
      type: actionTypes.FET_SURVEY_FAIL,
      payload: error,
    });
  }
}
export function* fetchSurveyDetail(action) {
  try {
    const req = yield call(actions.fetSurveyDetail, action.payload);
    yield put({
      type: actionTypes.FET_SURVEY_DETAIL_SUCCESS,
      payload: req,
    });
  } catch (error) {
    yield put({
      type: actionTypes.FET_SURVEY_DETAIL_FAIL,
      payload: error,
    });
  }
}



export function* fetchServiceNoti(action) {
  try {
      const req = yield call(actions.fetchServiceNoti, action.payload)
      yield put({ type: "FETCH_SERVICE_NOTI_SUCCEESS", payload: req.data, filter: action.payload })
  } catch (error) {
      //if (checkOnlineAndToken(error)) {
          yield put({ type: "FETCH_SERVICE_NOTI_FAILED", action: error })
      //}
  }
}

export function* fetchUnreadServiceNoti(action) {
  try {
      const req = yield call(actions.fetchUnreadNoti, action.payload)
      yield put({ type: "FETCH_UNREAD_SERVICE_NOTI_SUCCEESS", payload: req.data })
  } catch (error) {
      //if (checkOnlineAndToken(error)) {
          yield put({ type: "FETCH_UNREAD_SERVICE_NOTI_FAILED", action: error })
      //}
  }
}

export function* fetchServiceNotiById(action) {
  try {
      const req = yield call(actions.fetchServiceNotiById, action.payload)
      // const req = yield call(actions.fetchServiceNoti, action.payload)
      yield put({ type: "FETCH_SERVICE_NOTI_BY_ID_SUCCEESS", payload: req.data })
  } catch (error) {
      //if (checkOnlineAndToken(error)) {
          yield put({ type: "FETCH_SERVICE_NOTI_BY_ID_FAILED", action: error })
      //}
  }
}

export function* putSeenServiceNoti(action) {
  try {
      const req = yield call(actions.putNotiToSeen, action.payload)
      yield put({ type: "PUT_SEEN_SERVICE_NOTI_SUCCEESS", payload: req.data })
      yield call(fetchUnreadServiceNoti, { payload: action.payload.filter })
  } catch (error) {
      //if (checkOnlineAndToken(error)) {
          yield put({ type: "PUT_SEEN_SERVICE_NOTI_FAILED", action: error })
      //}
  }
}

export function* postSeenAllServiceNoti(action) {
  try {
      const req = yield call(actions.postAllNotiToSeen, action.payload.filter)
      yield put({ type: "PUT_SEEN_ALL_SERVICE_NOTI_SUCCEESS", payload: req.data })
      yield call(fetchUnreadServiceNoti, { payload: action.payload.filter })
  } catch (error) {
      //if (checkOnlineAndToken(error)) {
          yield put({ type: "PUT_SEEN_ALL_SERVICE_NOTI_FAILED", action: error })
      //}
  }
}
export function* fetchEventTotalEntry({ params }) {
  try {
    const { data } = yield call(actions.fetchEventTotalEntry, params);
    yield put({
      type: actionTypes.FETCH_EVENT_TOTAL_ENTRY_SUCCESS,
      payload: data,
      loadingType: "fetchEventTotalEntry",
    });
  } catch (error) {
    yield put({
      type: actionTypes.ONLINE_TIME_FAIL,
      loadingType: "fetchEventTotalEntry",
    });
  }
}

export function* fetchAppModuleUsageTime({ params }) {
  try {
    const { data } = yield call(actions.fetchAppModuleUsageTime, params);
    yield put({
      type: actionTypes.FETCH_APP_MODULE_USAGE_TIME_SUCCESS,
      payload: data,
      loadingType: "fetchAppModuleUsageTime",
    });
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_APP_MODULE_USAGE_TIME_FAIL,
      loadingType: "fetchAppModuleUsageTime",
    });
  }
}

export function* fetchDashboardScanning({ params }) {
  try {
    const { data } = yield call(actions.fetchDashboardScanning, params);
    yield put({
      type: actionTypes.FETCH_DASHBOARD_SCANNING_SUCCESS,
      payload: data,
      loadingType: "fetchDashboardScanning",
    });
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_DASHBOARD_SCANNING_FAIL,
      loadingType: "fetchDashboardScanning",
    });
  }
}
export function* fetchTop5SearchProduct({ params }) {
  try {
    const { data } = yield call(actions.fetchTop5SearchProduct, params);
    yield put({
      type: actionTypes.FETCH_TOP5_SEARCH_PRODUCT_SUCCESS,
      payload: data,
      loadingType: "fetchTop5SearchProduct",
    });
  } catch (error) {
    yield put({
      type: actionTypes.FETCH_TOP5_SEARCH_PRODUCT_FAIL,
      loadingType: "fetchTop5SearchProduct",
    });
  }
}
