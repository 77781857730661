import * as actionTypes from "./../actions/actionTypes";
const initialState = {
  loading: false,
  data: {},
};
const Products = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FET_PRODUCTS:
      state.loading = true;
      return { ...state };
    case actionTypes.FET_PRODUCTS_SUCCESS:
      state.data = action.payload.data;
      state.loading = false;
      return { ...state };
    case actionTypes.FET_PRODUCTS_FAIL:
      state.loading = false;
      return { ...state };

    default:
      return { ...state };
  }
};
export default Products;
