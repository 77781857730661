import * as actionTypes from "../actions/actionTypes";
import {produce} from 'immer'

const initialState = {
  loading: false,
  data: undefined,
  detail: undefined,
  error: false
};

const LuckyDrawEventReducer = produce((state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_LUCKY_DRAW_EVENTS:
      state.loading = true
      state.error = false
      return state
    case actionTypes.FETCH_LUCKY_DRAW_EVENTS_DETAIL:
      state.loading = true
      state.error = false
      return state
    case actionTypes.FETCH_LUCKY_DRAW_EVENTS_SUCCESS:
      state.loading = false
      state.data = action.payload
      return state;
    case actionTypes.FETCH_LUCKY_DRAW_EVENTS_FAIL:
      state.loading = false;
      state.error = true
      return state;
    
    case actionTypes.FETCH_LUCKY_DRAW_EVENTS_DETAIL_SUCCESS:
      state.detail = action.payload
      state.loading = false
      return state;
    case actionTypes.FETCH_LUCKY_DRAW_EVENTS_DETAIL_FAIL:
      state.loading = false
      state.error = true
      return state;
    case actionTypes.CLEAR_LUCKY_DRAW_EVENTS_DETAIL:
      state = initialState
      return state
    case actionTypes.CLEAR_LUCKY_DRAW_EVENTS:
      state = initialState
      return state
    default:
      return state;
  }
});
export default LuckyDrawEventReducer;