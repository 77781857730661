import { produce } from 'immer';
import _ from 'lodash'

const initState = {
  loading: false,
  submiting: false,
  total: undefined,
  data: [],
  select: {},
  error: undefined
}

const GroupUserReducer = produce((draft = initState, action = {}) => {
  switch (action.type) {
    case "FETCH_GROUP_USERS":
      draft.loading = true
      return draft
    case "FETCH_GROUP_USERS_SUCCESS":
      draft.loading = false
      draft.data = action.payload && action.payload.data;
      draft.total = action.payload && action.payload.total;
      return draft
    case "GET_GROUP_USER_DETAIL_SUCCESS":
      draft.select = action.payload;
      return draft
    case "CLEAR_GROUP_USER_DETAIL":
      draft.select = {}
      return draft
    case 'UPDATE_PERMISSION_INFO_REDUCER':
      let index = _.findIndex(draft.data, ['id', draft.select && draft.select.id])
      if (index >= 0) {
        draft.data[index] = action.payload
      }
      return draft
    default:
      return draft
  }
})

export default GroupUserReducer